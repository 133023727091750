<template>
  <BaseDropdown
    v-model:selection="failedInstallationAttempt.reason"
    label="Grund für keine Montage"
    dropdown-text="Grund wählen"
    test-id="failedMountAttemptReason"
    :options-list="notMountedReasonsListInspection"
    :validation="v$.failedInstallationAttempt.reason"
    :numbered="true"
  />
  <BaseTextarea
    v-model:input-text="failedInstallationAttempt.note"
    test-id="failedMountAttemptNote"
    label="Bemerkung"
    class="mb-3"
    :validation="v$.failedInstallationAttempt.note"
  />
  <BaseSection heading="Foto">
    <BaseInformation
      information-text="Zur Veranschaulichung des Grundes, weshalb das Gerät nicht montiert werden kann, nehmen Sie bitte ein Foto auf."
    />
    <Checkbox
      v-model:checked="isImageNotAllowedChecked"
      label="Nutzer verweigert Foto"
      checkbox-id="failedInstallationAttemptCheckbox"
    />
    <ImageCapture
      ref="imageCaptureRef"
      class="mt-4"
      :object-id="failedInstallationAttempt.id"
      :object-type="ImageObjectType.UNMOUNTED_DEVICE"
    />
  </BaseSection>
</template>
<script setup lang="ts">
import { notMountedReasonsListInspection } from '@/enums/device';
import { ImageObjectType } from '@/models/Image';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import Checkbox from '@/components/UI/Checkbox/Checkbox.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import ImageCapture, {
  ImageCaptureRef,
} from '@/components/Forms/DeviceFormComponents/ImageCapture.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import { computed, Ref, ref } from 'vue';
import { useValidation } from '@/composables/useValidation';
import { FailedInstallationAttempt } from '@/models/installationPoint/InstallationPoint';

const failedInstallationAttempt = defineModel('failedInstallationAttempt', {
  type: FailedInstallationAttempt,
});

const isImageNotAllowedChecked = ref<boolean>(false);

const imageCaptureRef: Ref<ImageCaptureRef | null> = ref(null);

const { v$, isFormValid } = useValidation({ failedInstallationAttempt });

defineExpose({
  isFormValid: computed(
    () =>
      isFormValid.value &&
      (imageCaptureRef.value?.hasImage || isImageNotAllowedChecked.value)
  ),
  saveImage: () => imageCaptureRef.value?.saveImage(),
});
</script>
