<template>
  <BaseToggle
    v-model:is-checked="isMounted"
    :label="label"
    test-id="failedMountAttempt"
    :is-inverted="true"
    @update:is-checked="onToggle"
  />
  <NoMountingInputFields
    v-if="!isMounted && failedInstallationAttempt"
    ref="noMountingSelectorRef"
    v-model:failedInstallationAttempt="failedInstallationAttempt"
  />
  <div
    v-if="
      !isMounted &&
      failedInstallationAttempt?.reason ===
        notMountedReasonsListInspection[2].value
    "
    class="flex"
  >
    <BaseFormInput
      v-model.number="counterPressure"
      label="Ventilgegendruck"
      inputmode="decimal"
      type="number"
      test-id="counterPressure"
      class="grow"
    />
    <div class="p-3">
      <div class="relative">
        <label
          class="absolute left-2.5 top-4 origin-[0] -translate-y-4 scale-75 text-sm dark:text-white"
        >
          Maßeinheit
        </label>
        <div
          class="px-2.5 pb-2.5 pt-5 text-sm text-gray-900 dark:text-gray-300"
        >
          Newton
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseToggle from '@/components/UI/Switch/BaseToggle.vue';

import { FailedInstallationAttempt } from '@/models/installationPoint/InstallationPoint';
import NoMountingInputFields from '@/components/Forms/DeviceFormComponents/NoMountingInputFields.vue';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import { computed, ref } from 'vue';
import { notMountedReasonsListInspection } from '@/enums/device';
import { useValidation } from '@/composables/useValidation';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';

const props = defineProps<{
  label: string;
  inspectionPoint: InspectionPoint;
}>();

const inspectionPoint = computed(() => props.inspectionPoint);
const failedInstallationAttempt = defineModel('failedInstallationAttempt', {
  type: FailedInstallationAttempt,
});
const counterPressure = defineModel('counterPressure', { type: Number });
const isMounted = defineModel('isMounted', { type: Boolean });

const onToggle = (newValue: boolean) => {
  if (!newValue) {
    failedInstallationAttempt.value = new FailedInstallationAttempt();
    return;
  }
  failedInstallationAttempt.value = undefined;
};
const noMountingSelectorRef = ref();
const { v$, isFormValid } = useValidation({
  inspectionPoint,
});
defineExpose({
  isFormValid: computed(
    () => noMountingSelectorRef.value?.isFormValid && isFormValid.value
  ),
  saveImage: () => noMountingSelectorRef.value?.saveImage(),
});
</script>
