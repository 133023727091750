<template>
  <div class="py-3">
    <div class="relative">
      <button
        v-click-outside="closeDropdown"
        type="button"
        :disabled="disabled"
        class="peer flex w-full appearance-none items-center justify-between rounded-t-lg border-0 border-b-2 border-gray-400 bg-light-1 px-2.5 pb-1.5 pt-4 text-gray-900 focus:border-primary-2 focus:outline-none focus:ring-0 dark:bg-dark-2 dark:text-white"
        :class="{
          'border-yellow-600 dark:border-yellow-600': warning,
          'dark:border-dark-3 dark:focus:border-primary-2': true,
          'bg-light-1 text-gray-900 dark:bg-dark-2 dark:text-white': !disabled,
          'dark:bg-dark-disabled-background dark:text-dark-disabled-text':
            disabled,
        }"
        :data-testid="'dropdown_' + dataTestId"
        @click="toggleDropdown"
      >
        <span v-if="!selection" class="text-gray-500 dark:text-gray-400">
          {{ dropdownText }}
        </span>

        <span v-else>
          <em
            v-if="selectedOption?.icon"
            :class="`mdi mdi-${selectedOption?.icon} mr-2`"
          />
          <span>{{ selectedOption?.name }}</span>
        </span>

        <em
          class="mdi ml-3 text-xl"
          :class="isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        ></em>
      </button>
      <label
        for="floating_outlined"
        class="absolute left-2.5 top-3 z-10 origin-[0] -translate-y-4 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-primary-2 dark:text-gray-400 peer-focus:dark:text-primary-2"
        >{{ label }}
        <span v-if="!isRequired || optional" class="ml-1 text-xs"
          >(optional)</span
        ></label
      >

      <!-- Dropdown menu -->
      <div
        class="absolute z-20 mb-4 w-full rounded-b-lg bg-light-1 shadow-md dark:bg-dark-2"
        :class="isOpen ? '' : 'hidden'"
      >
        <ul
          class="my-0 max-h-[293px] list-none overflow-y-auto pb-2 pl-0 pt-1 text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          <div v-if="recentOptionsList.length > 0">
            <li
              v-for="(option, index) in recentOptionsList"
              :key="option.name + index"
              class="my-0 block px-4 py-3 no-underline hover:bg-light-0 dark:hover:bg-gray-600 dark:hover:text-white"
              :class="
                index !== recentOptionsList.length - 1
                  ? 'border-b border-b-light-0  dark:border-b-dark-3'
                  : ''
              "
              :data-testid="'optiondropdown_' + dataTestId + '_' + option.name"
              @click="selectValue(option.value)"
            >
              <em v-if="option.icon" :class="`mdi mdi-${option.icon} mr-2`" />
              <span v-if="numbered"> {{ index }} - </span>{{ option.name }}
            </li>
            <li class="border-t-2 border-t-light-0 dark:border-t-dark-5"></li>
          </div>
          <li
            v-for="(option, index) in optionsList"
            :key="option.name + index"
            class="my-0 block px-4 py-3 no-underline hover:bg-light-0 dark:hover:bg-gray-600 dark:hover:text-white"
            :class="
              index !== optionsList.length - 1
                ? 'border-b border-b-light-0  dark:border-b-dark-3'
                : ''
            "
            :data-testid="'optiondropdown_' + dataTestId + '_' + option.name"
            @click="selectValue(option.value)"
          >
            <em v-if="option.icon" :class="`mdi mdi-${option.icon} mr-2`" />
            <span v-if="numbered"> {{ index }} - </span>{{ option.name }}
          </li>
        </ul>
      </div>
      <div
        v-if="warning && warningMessage"
        data-testId="inputWarning"
        class="mt-0 text-xs text-yellow-600 dark:text-yellow-500"
      >
        <em class="mdi mdi-alert pr-1"> </em> {{ warningMessage }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

type DropdownOption = {
  name: string;
  value: string;
  icon?: string;
};

const props = defineProps({
  optionsList: {
    type: Object as () => Array<DropdownOption> | ReadonlyArray<DropdownOption>,
    required: true,
  },
  recentOptionsList: {
    type: Object as () => Array<DropdownOption> | ReadonlyArray<DropdownOption>,
    required: false,
    default: Object as () => [],
  },
  numbered: {
    type: Boolean,
    default: false,
  },
  selection: {
    type: String,
    required: false,
    default: undefined,
  },
  dropdownText: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    required: true,
  },
  validation: {
    type: Object,
    required: false,
    default: undefined,
  },
  optional: {
    type: Boolean,
    default: false,
  },
  testId: {
    type: String,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  warning: {
    type: Boolean,
    default: false,
  },
  warningMessage: {
    type: String,
    default: undefined
  }
});

const emit = defineEmits(['update:selection']);

const isOpen = ref<boolean>(false);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const closeDropdown = () => {
  isOpen.value = false;
};

const isRequired = computed(() => !!props.validation?.required ?? false);

const selectValue = (value: string) => {
  emit('update:selection', value);
  closeDropdown();
};

const dataTestId = computed(() => {
  return props.testId ? props.testId : props.label;
});

const selectedOption = computed(() => {
  return props.optionsList.find((option) => option.value === props.selection);
});
</script>

<style scoped>
li:hover {
  cursor: pointer;
}
</style>
