type ArrowDirections = {
  left: boolean;
  right: boolean;
  onClick?: () => void;
};

type Action = any; // Define the Action type based on your specific use case

export class Node {
  id: string | undefined;
  label: string | undefined;
  notificationIcons: any[]; // Specify the type instead of any if possible
  tags: Tag[];
  actions: Action[];
  children: Node[];
  secondaryLabels: string[];
  isActive: boolean;
  selfAction: any | undefined; // Specify the type instead of any if possible
  arrows: ArrowDirections;
  totalNumberOfChildren: number;
  maxDepth: number;

  constructor() {
    this.id = undefined;
    this.label = undefined;
    this.notificationIcons = [];
    this.tags = [];
    this.actions = [];
    this.children = [];
    this.secondaryLabels = [];
    this.isActive = false;
    this.selfAction = undefined;
    this.arrows = {
      left: false,
      right: false,
      onClick: undefined,
    };
    this.totalNumberOfChildren = 0;
    this.maxDepth = 1;
  }
}

export class TagCustomStyle {
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  iconClass?: string;
  testId?: string;
}

export class Tag {
  label: string;
  info: number;
  color: string;
  customStyle?: {
    borderColor?: string;
    backgroundColor?: string;
    textColor?: string;
    iconClass?: string;
    testId?: string;
  };

  constructor(
    label: string,
    info: number,
    color: string,
    customStyle?: TagCustomStyle
  ) {
    this.label = label;
    this.info = info;
    this.color = color;
    this.customStyle = customStyle;
  }
}
