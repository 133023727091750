<template>
  <BaseSection heading="Altgerät" data-testid="oldDevice_expansion">
    <PlusButton
      v-if="!hasOldDevice"
      title="Altgerät dokumentieren"
      data-testId="old-device-toggle"
      @click="() => (hasOldDevice = !hasOldDevice)"
    />

    <div v-if="hasOldDevice">
      <div class="flex flex-row items-end justify-end">
        <em
          v-if="!isDisabled"
          class="mdi mdi-close text-2xl"
          data-testId="remove-old-device"
          @click="
            () => {
              resetOldDevice();
              hasOldDevice = false;
            }
          "
        />
      </div>

      <BaseTextarea
        v-if="showReplacementReason"
        v-model:input-text="oldDeviceEvent.reason"
        :validation="v$.oldDeviceEvent.reason"
        label="Grund für Geräteaustausch"
        test-id="replacement_reason"
      />
      <div class="md:flex md:gap-4">
        <BaseFormInput
          v-model="oldDevice.serialNumber"
          class="md:basis-1/2"
          :disabled="isDisabled"
          label="Seriennummer"
          :optional="false"
          :validation="v$.oldDevice.serialNumber"
          test-id="oldDeviceSerialnumber"
        />
        <BaseFormInput
          v-model="oldDevice.productKey"
          class="md:basis-1/2"
          :disabled="isDisabled"
          :validation="v$.oldDevice.productKey"
          label="Artikelnummer"
          test-id="oldDeviceProductkey"
        />
      </div>
      <div v-if="isMeteringDevice" class="md:flex md:gap-4">
        <BaseFormInput
          v-model="oldDevice.yearOfConformity"
          class="pr-2 md:basis-1/2"
          label="Eichjahr"
          :disabled="isDisabled"
          :validation="v$.oldDevice.yearOfConformity"
          type="number"
          inputmode="number"
          pattern="[0-9]{4}"
          placeholder="yyyy"
          test-id="oldDeviceYearOfConformity"
        />
      </div>
      <DateMount
        v-model:deviceEvent="oldDeviceEvent"
        :disabled="isEditMode"
        :validation="v$.oldDeviceEvent"
        test-id="unmounting-date"
        label="Demontagedatum"
      />
      <div v-if="isMeasuringDevice" class="md:flex md:gap-4">
        <div class="md:basis-1/2">
          <BaseFormInput
            v-model.number="oldDeviceEvent.counter"
            :disabled="isEditMode"
            label="Zählerstand bei Ausbau"
            inputmode="numeric"
            pattern="[0-9]*"
            :validation="v$.oldDeviceEvent.counter"
            placeholder="0,000"
            test-id="oldDeviceCounterOnUnmount"
            type="number"
          />
        </div>
        <div class="md:basis-1/2">
          <MeasurementUnit
            v-model:unit="oldDevice.measurementUnit"
            :disabled="isEditMode"
            :device="oldDevice"
            :validation="v$.oldDevice.measurementUnit"
            test-id="oldDeviceMeasurementUnit"
          />
        </div>
      </div>
      <div v-if="isOldHCA" class="md:flex md:gap-4">
        <div class="md:basis-1/2">
          <BaseFormInput
            v-model.number="oldDeviceEvent.controlValue"
            :maxlength="2"
            label="Kontrollwert für Verdunster"
            inputmode="numeric"
            pattern="[0-9]{2}"
            placeholder="0"
            test-id="oldDeviceControlValueOnUnmount"
            type="number"
          />
        </div>
        <div class="md:basis-1/2">
          <div class="p-3">
            <div class="relative">
              <label
                class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 text-sm text-gray-500 dark:text-gray-400"
              >
                Maßeinheit
              </label>
              <div
                class="px-2.5 pb-2.5 pt-5 text-sm text-gray-900 dark:text-white"
              >
                mm
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseTextarea
        v-model:input-text="oldDevice.note"
        placeholder="Notizen zum Altgerät"
        label="Notizen zum Altgerät"
        test-id="oldDeviceNote"
      >
      </BaseTextarea>
    </div>
  </BaseSection>
</template>

<script setup lang="ts">
import MeasurementUnit from '@/components/Forms/DeviceFormComponents/MeasurementUnit.vue';
import {
  computed,
  onMounted,
  ref,
  WritableComputedRef,
} from 'vue';
import { DeviceClasses } from '@/models/devices/Device';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import { DeviceEventParams } from '@/models/installationPoint/DeviceEvent';
import { deviceTypes } from '@/enums/device';
import { FormAction, useFormStore } from '@/store/form/formStore';
import PlusButton from '@/components/UI/Button/PlusButton.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import DateMount from '@/components/Forms/DeviceFormComponents/DateMount.vue';
import { useValidation } from '@/composables/useValidation';

const props = defineProps<{
  oldDevice: DeviceClasses;
  oldDeviceEvent: DeviceEventParams;
  resetOldDevice: () => void;
}>();

const emit = defineEmits(['update:oldDevice', 'update:oldDeviceEvent']);

const hasOldDevice = ref<boolean>(false);
const showReplacementReason = ref<boolean>(false);

const isReplacement = useFormStore().formAction === FormAction.REPLACE;
const hasReplacement =
  props.oldDevice?.serialNumber?.length > 0 &&
  props.oldDeviceEvent.type === 'UNMOUNT_EVENT' &&
  (props.oldDeviceEvent?.reason?.length ?? 0) > 0;

onMounted(() => {
  hasOldDevice.value = !!oldDevice?.value?.serialNumber;
  showReplacementReason.value = isReplacement || hasReplacement;
});

const isDisabled = computed(() => {
  const action = useFormStore().formAction;
  return action === FormAction.REPLACE || action === FormAction.EDIT;
});

const isEditMode = computed(() => {
  return useFormStore().formAction === FormAction.EDIT;
});

const oldDevice: WritableComputedRef<DeviceClasses> = computed({
  get() {
    return props.oldDevice;
  },
  set(value) {
    emit('update:oldDevice', value);
  },
});

const oldDeviceEvent: WritableComputedRef<DeviceEventParams> = computed({
  get() {
    return props.oldDeviceEvent;
  },
  set(value) {
    emit('update:oldDeviceEvent', value);
  },
});

const serialNumberLabel = computed(() => {
  return isOldLoRaWanGatewayDevice.value ? 'Artikelnummer' : 'Seriennummer';
});

const isMeasuringDevice = computed(() => {
  return oldDevice.value.isMeasuringDevice();
});

const isOldHCA = computed(() => {
  return oldDevice.value.deviceType === deviceTypes.HeatCostAllocator;
});

const isOldLoRaWanGatewayDevice = computed(() => {
  return oldDevice.value.deviceType == deviceTypes.LoRaWanGateway;
});

const isMeteringDevice = computed(() => {
  return (
    oldDevice.value.isMeteringDevice() &&
    oldDevice.value.deviceType !== deviceTypes.HeatCostAllocator
  );
});

const { v$, isFormValid } = useValidation(
  { oldDeviceEvent, oldDevice },
  {
    isReplacement: isReplacement,
    hasReplacement: hasReplacement,
  }
);

defineExpose({
  isFormValid,
});
</script>
<style scoped></style>
