import { ref } from 'vue';
import { instanceToInstance } from 'class-transformer';
import { InspectedBuilding } from '@/models/InspectedBuilding';
import { useInspectedBuildingStore } from '@/store/entities/inspectedBuildingStore';

export function useInspectedBuildingForm(
  node?: InspectedBuilding | undefined,
  buildingId?: string,
  imageCaptureRef?: any,
  emit?: any
) {
  const inspectedBuilding = ref(new InspectedBuilding());

  const getInspectedBuilding = (buildingId: string) => {
    const existingInspectedBuilding =
      useInspectedBuildingStore().getInspectedBuildingByBuildingId(buildingId);
    if (existingInspectedBuilding) {
      inspectedBuilding.value = instanceToInstance(existingInspectedBuilding);
    }
    return inspectedBuilding.value;
  };

  const initializeInspectedBuildingForm = () => {
    if (!node?.buildingId) {
      inspectedBuilding.value.buildingId = buildingId as string;

      inspectedBuilding.value.systemSRTs =
        useInspectedBuildingStore().getSystemSRTs(
          inspectedBuilding.value.buildingId
        );
    } else {
      inspectedBuilding.value = instanceToInstance(node);
    }
  };

  const setupNewInspectedBuilding = () => {
    inspectedBuilding.value.buildingId = buildingId;
  };

  const handleSubmit = () => {
    emit('update-entity', inspectedBuilding.value);

    if (imageCaptureRef.value) {
      imageCaptureRef.value.saveImage();
    }
  };

  return {
    inspectedBuilding,
    getInspectedBuilding,
    initializeInspectedBuildingForm,
    setupNewInspectedBuilding,
    handleSubmit,
  };
}
