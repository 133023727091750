import { required } from '@vuelidate/validators';
import { Ref } from 'vue';
import { deviceTypes } from '@/enums/device';
import { DeviceClasses } from '@/models/devices/Device';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';

const messages = {
  required: 'Der Wert ist erforderlich',
  invalidDate: 'Das Datum ist ungültig',
  invalidSerialNumber: (device: Ref<DeviceClasses>) => {
    const length =
      device.value.deviceType === deviceTypes.SmartRadiatorThermostat ? 12 : 8;
    const content =
      device.value.deviceType === deviceTypes.SmartRadiatorThermostat
        ? 'und Buchstaben sowie Zahlen beinhalten.'
        : 'und darf ausschließlich Zahlen beinhalten.';
    return `Die Seriennummer sollte ${length} Zeichen lang sein ${content}`;
  },
  invalidManufacturer: (device: Ref<DeviceClasses>) => {
    if (device.value.deviceType === deviceTypes.SmartRadiatorThermostat) {
      return "Zurzeit wird nur der Hersteller 'Tado' unterstützt.";
    }

    return messages.required;
  },

  invalidInspectionPointCounterPressure: (
    inspectionPoint: Ref<InspectionPoint>
  ) => {
    let maxValue = 90;
    if (
      inspectionPoint.value.accessories.find(
        (accessory) => accessory.customizedNumber === '42051'
      )
    ) {
      maxValue = 80;
    }
    return `Die Eingabe darf 4 Ziffern mit einer Dezimalstelle und einen Wert im Bereich von 0 bis ${maxValue} haben (z. B. 000,0)`;
  },

  invalidEui: `Der Wert sollte 16 Hexadezimalziffern (1-9, A-F) lang sein.`,
  invalidYearOfConformity: `Das Eichjahr sollte 4 Zeichen lang sein (z.B.: 2023)`,
  invalidConstructionYear: `Das Baujahr sollte 4 Zeichen lang sein (z.B.: 1950)`,
  invalidSerialNumberBatteryPack: `Die Seriennummer sollte 12 Zeichen lang sein und dem folgenden Beispielformat entsprechen: MB1234567890`,
  invalidCounterPressure: `Die Eingabe darf 4 Ziffern mit einer Dezimalstelle und einen Wert im Bereich von 0 bis 90 haben (z. B. 000,0)`,
  resetCode: 'Der Code ist 6 Zeichen lang.',
  password: 'Das Passwort muss mindestens 16 Zeichen lang sein.',
  sameAsPassword: 'Die Passwörter stimmen nicht überein.',
  invalidStartDate: `Das Startdatum sollte vor dem Enddatum liegen.`,
  invalidEndDate: `Das Enddatum sollte nach dem Startdatum liegen.`,
  invalidDeviceReference: `Die Seriennummer sollte 8 Zeichen lang sein.`,
};

/*
 * Default messages can be overwritten here
 * for simple validators
 */
required.$message = messages.required;

export { required, messages };
