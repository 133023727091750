<template>
  <div>
    <BaseSection heading="Adressen" :hint="countAddresses">
      <BaseDropdown
        :selection="addressSelection"
        label="Adresse"
        dropdown-text="Adresse auswählen"
        :options-list="buildingAddressList"
        :optional="false"
        :warning="isAddressDuplicated.address"
        :warning-message="ADDRESS_WARNING_MESSAGE"
        test-id="buildingAddress"
        :validation="{ required: true }"
        @update:selection="setBuildingAddress"
      />

      <div v-if="hasCustomAddress" class="mt-2 md:flex md:gap-3">
        <BaseFormInput
          v-model="localAddress.street"
          class="md:basis-1/2"
          label="Straße"
          test-id="input_buildingAddressStreet"
          :validation="validation.building.address.street"
        />

        <BaseFormInput
          v-model="localAddress.houseNumber"
          class="md:basis-1/2"
          label="Hausnummer"
          test-id="input_buildingAddressHouseNumber"
          :validation="validation.building.address.houseNumber"
        />
      </div>
      <div v-if="hasCustomAddress" class="md:flex md:gap-4">
        <div class="md:basis-1/2">
          <BaseFormInput
            v-model="localAddress.zipCode"
            label="PLZ"
            type="number"
            pattern="[0-9]"
            inputmode="numeric"
            test-id="input_buildingAddressZipCode"
            :validation="validation.building.address.zipCode"
          />
        </div>
        <div class="md:basis-1/2">
          <BaseFormInput
            v-model="localAddress.city"
            autocomplete="address-level2"
            label="Ort"
            type="text"
            test-id="input_buildingAddressCity"
            :validation="validation.building.address.city"
          />
        </div>
      </div>

      <div class="md:flex md:gap-4">
        <div class="md:basis-1/2">
          <BaseDropdown
            v-model:selection="building.buildingSection"
            label="Lage"
            dropdown-text="Lage wählen"
            :warning="isAddressDuplicated.section"
            :warning-message="WARNING_MESSAGE"
            :options-list="buildingSectionList"
            :validation="validation.building.buildingSection"
            @update:selection="checkAddressCombination('section')"
          />
        </div>

        <div class="md:basis-1/2">
          <BaseDropdown
            v-model:selection="building.entrance"
            label="Eingang"
            dropdown-text="Eingang wählen"
            class="md:basis-1/2"
            :options-list="entranceList"
            :warning="isAddressDuplicated.entrance"
            :warning-message="WARNING_MESSAGE"
            :validation="validation.building.entrance"
            @update:selection="checkAddressCombination('entrance')"
          />
        </div>
      </div>
    </BaseSection>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue';
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import { Address } from '@/models/types/Address';
import { useBuildingStore } from '@/store/entities/buildingStore';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import { buildingSectionList, entranceList } from '@/enums/building';
import { Building } from '@/models';

const building = defineModel<Building>('building');

const props = defineProps<{
  address: Address;
  validation?: any;
}>();

const localAddress = ref(new Address());

onMounted(() => {
  const selectedAddress = findAddressById(props.address.id);
  if (selectedAddress) {
    addressSelection.value = selectedAddress.id;
    return;
  }

  setOriginalAddress();
});

const emit = defineEmits(['update:address']);

const ADDRESS_WARNING_MESSAGE =
  'Die ausgewählte Adresse wird bereits verwendet';
const WARNING_MESSAGE = 'Die ausgewählte Kombination wird bereits verwendet';

const hasCustomAddress = ref(false);
const addressSelection = ref();

const buildingAddresses = computed(() => useBuildingStore().buildingAddresses);
const buildingAddressList = computed(() =>
  getBuildingAddressList(buildingAddresses.value)
);

function getBuildingAddressList(addresses: Address[]) {
  const optionList = addresses.map((address) => ({
    value: address.id,
    name: `${address.street} ${address.houseNumber}`,
  }));
  optionList.push({ value: 'CUSTOM', name: 'Adresse hinzufügen' });
  return optionList;
}

function setBuildingAddress(value: string) {
  const selectedAddress = findAddressById(value);
  if (value !== addressSelection.value) {
    isAddressDuplicated.value.address =
      checkIfAddressAlreadyExists(selectedAddress);
  }

  if (selectedAddress) {
    updateBuildingAddress(selectedAddress);
    return;
  }
  if (value === 'CUSTOM') {
    setCustomAddressState();
  }
}

const countAddresses = computed(() => {
  const count = buildingAddresses.value.length;
  if (count !== 0) return `(${count})`;
  return '';
});

function findAddressById(id: string | undefined) {
  return buildingAddresses.value.find((address) => address.id === id);
}

function updateBuildingAddress(address: Address) {
  emit('update:address', address);
  addressSelection.value = address.id;
  hasCustomAddress.value = false;
}

function setCustomAddressState() {
  addressSelection.value = 'CUSTOM';
  hasCustomAddress.value = true;
  localAddress.value = new Address();
  emit('update:address', localAddress.value);
}

const isAddressFilled = computed(() => {
  return (
    props.address.street ||
    props.address.houseNumber ||
    props.address.zipCode ||
    props.address.city
  );
});

function setOriginalAddress() {
  if (isAddressFilled.value) {
    hasCustomAddress.value = true;
    addressSelection.value = 'CUSTOM';
  }

  localAddress.value = props.address;
}

const isAddressDuplicated = ref<Record<string, any>>({
  address: false,
  entrance: false,
  section: false,
});

function checkAddressCombination(field: string) {
  const addressObject = findAddressById(addressSelection.value);
  Object.keys(isAddressDuplicated.value).forEach(
    (v: string) => (isAddressDuplicated.value[v] = false)
  );
  isAddressDuplicated.value[field] = checkIfAddressAlreadyExists(addressObject);
}

function checkIfAddressAlreadyExists(address?: Address) {
  if (!address) return false;
  const selectedBuilding = useBuildingStore()
    .getBuildings()
    .find((existingBuilding) => {
      return (
        existingBuilding.address.id === address.id &&
        existingBuilding.buildingSection === building.value?.buildingSection &&
        existingBuilding.entrance === building.value?.entrance
      );
    });
  return !!selectedBuilding;
}
</script>
