import { entityTypes } from '@/enums/generic';
import { OverallRadioCheckStatus } from '@/enums/radiocheck';
import { generateUniqueId } from '@/helpers/uuidUtils';
import { deviceTypes } from '@/enums/device';
import {
  DeviceType,
  FailedInstallationAttempt,
} from '@/models/installationPoint/InstallationPoint';
import { LoRaWanRadioCheck } from '@/api/funkcheck/LoRaWanRadioCheck';
import { DeviceClasses } from '@/models/devices/Device';
import { PlantDataClasses } from '@/models/plant/Plant';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Type } from 'class-transformer';
import { TagCustomStyle } from '@/utils/canvas/drawingUtils';
import {
  Adapter,
  Accessory,
  BatteryPack,
} from '@/models/installationPoint/Accessory';

export enum InspectionPointType {
  SRT_ADAPTER = 'SRT_ADAPTER',
}

export class InspectionPoint {
  id: string;
  type = entityTypes.inspectionPoint;
  roomId: string;
  activeTestDeviceId?: string;
  @Type(() => InspectionData)
  inspectionData: InspectionData;
  @Type(() => Accessory, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: [
        { value: Adapter, name: 'SRT_ADAPTER' },
        { value: Adapter, name: 'GATEWAY_HOUSING' },
        { value: BatteryPack, name: 'SRT_BATTERY_PACK' },
      ],
    },
  })
  accessories: Adapter[];
  billedInRoomGroupId: string;
  supportedDeviceType?: DeviceType;
  failedInstallationAttempt?: FailedInstallationAttempt;
  note?: string;

  constructor(roomId: string) {
    this.id = generateUniqueId();
    this.roomId = roomId;
    this.note = undefined;
    this.accessories = [];
    this.activeTestDeviceId = undefined;
    this.inspectionData = new InspectionData();
    this.billedInRoomGroupId = '';
    this.failedInstallationAttempt = undefined;
    this.supportedDeviceType = undefined;
  }

  isInspectionPointTypeAdapter() {
    return (
      this.inspectionData.inspectionType === InspectionPointType.SRT_ADAPTER
    );
  }

  setSupportedDeviceType(deviceType: DeviceType) {
    this.supportedDeviceType = deviceType;
  }

  addInspectionData(inspectionData: InspectionData) {
    this.inspectionData = inspectionData;
  }

  getActiveDevice(): DeviceClasses | PlantDataClasses {
    if (!this.activeTestDeviceId) throw Error('No active device id');
    return useEntitiesStore().getEntityById(this.activeTestDeviceId);
  }

  unsetActiveDevice() {
    this.activeTestDeviceId = undefined;
  }

  getParentId() {
    return this.roomId;
  }

  getLabel() {
    if (this.isInspectionPointTypeAdapter()) return 'Adapter/SRT';
    if (this.supportedDeviceType === deviceTypes.SmartRadiatorThermostat) {
      return 'Test-SRT';
    }
    return 'Gateway';
  }

  getTagColor() {
    if (this.isInspectionPointTypeAdapter()) return '#361E7A';
    if (this.supportedDeviceType === deviceTypes.SmartRadiatorThermostat) {
      return '#7c3aed';
    }
    if (this.supportedDeviceType === deviceTypes.LoRaWanGateway) {
      return '#22c55e';
    }

    return '#7c3aed';
  }

  _getGatewayStyling(inspectionPoints: OverallRadioCheckStatus[]) {
    if (inspectionPoints.includes(OverallRadioCheckStatus.COMPLETED)) {
      return {
        borderColor: 'border-green-600 dark:border-green-600',
        backgroundColor: 'bg-green-50 dark:bg-green-950',
        textColor: '',
        iconClass: 'mdi text-sm mdi-router-wireless mr-1',
        testId: 'gateway-status-badge',
      };
    } else if (inspectionPoints.includes(OverallRadioCheckStatus.FAILED)) {
      return {
        borderColor: 'border-red-600 dark:border-red-600',
        backgroundColor: 'bg-red-50 dark:bg-red-950',
        textColor: '',
        iconClass: 'mdi text-sm mdi-router-wireless mr-1',
        testId: 'gateway-status-badge',
      };
    } else if (inspectionPoints.includes(OverallRadioCheckStatus.CANCELED)) {
      return {
        borderColor: 'border-yellow-600 dark:border-yellow-600',
        backgroundColor: 'bg-yellow-50 dark:bg-yellow-950',
        textColor: '',
        iconClass: 'mdi text-sm mdi-router-wireless mr-1',
        testId: 'gateway-status-badge',
      };
    }
    return {
      borderColor: '',
      backgroundColor: '',
      textColor: '',
      iconClass: 'mdi text-sm mdi-router-wireless mr-1',
      testId: 'gateway-status-badge',
    };
  }

  getAdvancedTagStyling(
    inspectionPoints?: OverallRadioCheckStatus[]
  ): TagCustomStyle | undefined {
    if (
      this.inspectionData &&
      this.inspectionData.inspectionType === InspectionPointType.SRT_ADAPTER
    ) {
      return {
        borderColor: '#B69FF7',
        backgroundColor: '#361E7A',
        textColor: 'text-zinc-200',
      };
    }

    if (
      this.supportedDeviceType === deviceTypes.LoRaWanGateway &&
      inspectionPoints
    ) {
      return this._getGatewayStyling(inspectionPoints);
    }
    return undefined;
  }
}

export class InspectionData {
  lteReception?: number;
  lteReceptionDbm?: number;
  isLteDeviceAvailable?: boolean;
  hasMobileLteConnection?: boolean;
  radioCheckResult?: OverallRadioCheckStatus;
  radioChecks: Array<LoRaWanRadioCheck>;
  connectionOptions: Array<string>;
  connectionAvailable: boolean;
  cableLength?: number;
  counterPressure?: number;
  noAdapterNeeded: boolean;
  inspectionType?: InspectionPointType;

  constructor() {
    this.lteReception = undefined;
    this.lteReceptionDbm = undefined;
    this.isLteDeviceAvailable = true;
    this.hasMobileLteConnection = undefined;
    this.radioCheckResult = undefined;
    this.radioChecks = [];
    this.connectionOptions = [];
    this.connectionAvailable = true;
    this.cableLength = undefined;
    this.counterPressure = undefined;
    this.noAdapterNeeded = false;
    this.inspectionType = undefined;
  }

  setRadioCheckResult(
    result: LoRaWanRadioCheck,
    runId: number,
    startedAt?: string,
    updatedAt?: string
  ) {
    const radioCheckResult = {
      status: result.status,
      runId,
      startedAt,
      updatedAt,
      result: result.result,
    };
    this.radioCheckResult = result.status;
    this.radioChecks.push(radioCheckResult);
  }
}
