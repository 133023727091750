<template>
  <div>
    <div class="text-center">
      {{
        activeBuilding.address.street + ' ' + activeBuilding.address.houseNumber
      }}
    </div>
    <BaseSection heading="SRT Menge">
      <div class="md:flex md:gap-4">
        <BaseFormInput
          v-model.number="inspectedBuilding.systemSRTs"
          label="Errechnet vom System"
          disabled
          test-id="input_systemSrt"
          class="md:basis-1/2"
        />
        <BaseFormInput
          v-model.number="inspectedBuilding.estimatedSRTs"
          label="Geschätzt vom Installateur"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          test-id="input_estimatedSrt"
          class="md:basis-1/2"
        />
      </div>
    </BaseSection>
    <BaseSection heading="Hinweise">
      <BaseTextarea
        v-model:input-text="inspectedBuilding.note"
        label="Notizen"
        test-id="textarea_note"
      />
    </BaseSection>
    <BaseSection heading="Fotos">
      <ImageCapture
        ref="imageCaptureRef"
        :object-id="inspectedBuilding.id"
        :object-type="ImageObjectType.INSPECTED_BUILDING"
        :capture-image-information="true"
      />
    </BaseSection>
  </div>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, ref, Ref } from 'vue';
import { ImageObjectType } from '@/models/Image';
import ImageCapture, {
  ImageCaptureRef,
} from '@/components/Forms/DeviceFormComponents/ImageCapture.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import { InspectedBuilding } from '@/models/InspectedBuilding';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { useInspectedBuildingForm } from '@/composables/useInspectedBuildingForm';
import { useFormChangeTrackerPlugin } from '@/composables/useFormChangeTracker';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';

const props = defineProps<{
  node?: InspectedBuilding;
}>();

const emit = defineEmits(['update-entity']);

const imageCaptureRef: Ref<ImageCaptureRef | null> = ref(null);

const activeBuilding = computed(() => {
  return useBuildingStore().getCurrentBuilding();
});

const { inspectedBuilding, initializeInspectedBuildingForm, handleSubmit } =
  useInspectedBuildingForm(
    props.node,
    activeBuilding.value?.id,
    imageCaptureRef,
    emit
  );

onBeforeMount(() => {
  initializeInspectedBuildingForm();
  useFormChangeTrackerPlugin(inspectedBuilding);
});

defineExpose({
  isFormValid: true,
  handleSubmit,
});
</script>
