<template>
  <div v-if="inspectionPoint" data-testid="deviceType-adapter">
    <div class="mb-2 flex flex-row gap-2">
      <BaseChip
        v-if="inspectionPoint.failedInstallationAttempt"
        class="h-7"
        :icon="NOT_MOUNTED_ICON"
        type="danger"
      />
      <BaseChip
        class="h-7"
        :text="inspectionPointTagText"
        :custom-color="inspectionPointTagStyling?.backgroundColor"
        :border-color="inspectionPointTagStyling?.borderColor"
      />
    </div>

    <table
      v-if="!inspectionPoint.failedInstallationAttempt"
      class="m-0 w-full rounded-xl bg-light-3 dark:bg-dark-3"
      data-testid="adapter_table"
    >
      <thead class="bg-light-0 text-xs dark:bg-dark-2">
        <tr>
          <th scope="col" class="rounded-tl-xl px-6 py-3 uppercase">
            Lieferant Artikel
          </th>
          <th scope="col" class="px-6 py-3 uppercase">Artikelnummer</th>
          <th scope="col" class="relative rounded-tr-xl px-3 py-1.5">
            <OptionsDropdown
              data-testid="adapterOptionsDropdown"
              class="float-right text-sm font-light"
              icon="mdi-dots-vertical"
              direction-x="left"
              direction="down"
            >
              <li
                data-testid="editAdapterButton"
                @click="onEdit(inspectionPoint)"
              >
                <em class="mdi mdi-pencil mr-2" />Bearbeiten
              </li>
              <li
                data-testid="deleteAdapterButton"
                @click="onDelete(inspectionPoint)"
              >
                <em class="mdi mdi-delete mr-2" />Löschen
              </li>
            </OptionsDropdown>
          </th>
        </tr>
      </thead>
      <tbody v-if="inspectionPoint.accessories.length > 0">
        <tr
          v-for="accessory in inspectionPoint.accessories"
          :key="accessory.id"
          class="bg-light-3 text-base dark:border-dark-5 dark:bg-dark-3"
        >
          <td class="rounded-bl-xl px-6 py-4">
            {{ accessory.productKey }}
          </td>
          <td class="px-6 py-4">
            {{ accessory.customizedNumber }}
          </td>
          <td class="rounded-br-xl"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="bg-light-3 text-base dark:border-dark-5 dark:bg-dark-3">
          <td class="rounded-b-xl px-6 py-4" colspan="3">
            Kein Adapter notwendig
          </td>
        </tr>
      </tbody>
    </table>

    <table
      v-else
      class="m-0 w-full text-left"
      data-testid="failed_attempt_adapter_table"
    >
      <thead class="bg-light-0 text-xs dark:bg-dark-2">
        <tr>
          <th scope="col" class="rounded-tl-xl px-6 py-3 uppercase">Grund</th>
          <th scope="col" class="relative rounded-tr-xl px-3 py-1.5">
            <OptionsDropdown
              class="float-right text-sm font-light"
              icon="mdi-dots-vertical"
              direction-x="left"
              direction="down"
            >
              <li
                data-testid="editBuildingButton"
                @click="onEdit(inspectionPoint)"
              >
                <em class="mdi mdi-pencil mr-2" />Bearbeiten
              </li>
              <li
                data-testid="deleteBuildingButton"
                @click="onDelete(inspectionPoint)"
              >
                <em class="mdi mdi-delete mr-2" />Löschen
              </li>
            </OptionsDropdown>
          </th>
        </tr>
      </thead>
      <tbody class="text-base">
        <tr class="rounded-xl bg-light-3 dark:border-dark-5 dark:bg-dark-3">
          <td class="rounded-bl-xl px-6 py-4">
            {{ inspectionPoint.failedInstallationAttempt.reason }}
          </td>
          <td class="rounded-br-xl"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import BaseChip from '@/components/UI/Chip/BaseChip.vue';
import { NOT_MOUNTED_ICON } from '@/enums/icons';

const props = defineProps<{
  inspectionPoint: InspectionPoint;
  onEdit: (inspectionPoint: InspectionPoint) => void;
  onDelete: (inspectionPoint: InspectionPoint) => void;
}>();

const inspectionPointTagText = computed(() => {
  return `${props.inspectionPoint?.accessories.length} Adapter`;
});

const inspectionPointTagStyling = computed(() => {
  return props.inspectionPoint.getAdvancedTagStyling();
});
</script>

<style></style>
