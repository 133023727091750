<template>
  <div :data-testid="'deviceType-' + deviceTypeValue?.label">
    <div v-if="installationPoints">
      <div class="mb-3">
        <BaseChip
          :icon="NOT_MOUNTED_ICON"
          :number="installationPoints.length"
          type="danger"
        />
        <span class="ml-1 lg:text-lg"> Keine Montage möglich </span>
      </div>

      <table
        class="m-0 w-full rounded-xl text-left text-sm dark:bg-dark-3"
        aria-label="No Mounting Devices"
        data-testid="failed_attempt_devices_table"
      >
        <thead class="bg-light-0 text-xs uppercase dark:bg-dark-2">
          <tr>
            <th scope="col" class="rounded-tl-xl px-6 py-3">Gerätetyp</th>
            <th scope="col" class="px-6 py-3">Grund</th>
            <th scope="col" class="px-6 py-3"></th>
            <th scope="col" class="rounded-tr-xl px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          <InstallationPointTableElement
            v-for="ip in installationPoints"
            :key="ip.id"
            :installation-point="ip"
            :on-edit="onEdit"
            :on-delete="onDelete"
            :on-exchange="onExchange"
          />
        </tbody>
      </table>
    </div>

    <div v-if="devices">
      <div class="mb-3 inline-flex items-center">
        <div v-if="!deviceTypeValue">
          <GatewayStatusBadge :status="deviceType" />
          <span class="ml-2 lg:text-lg">
            {{ deviceTypeDetails[DeviceTypeEnum.LO_RA_WAN_GATEWAY]?.label }}
          </span>
        </div>
        <div v-else>
          <BaseChip
            :text="`${deviceTypeValue?.shortLabel}`"
            :number="devices.length"
            :custom-color="deviceTypeValue?.color"
          />
          <span class="ml-2 lg:text-lg">
            {{ deviceTypeValue?.label }}
          </span>
        </div>
      </div>

      <table
        class="m-0 w-full rounded-xl text-left dark:bg-dark-3"
        aria-label="Installed Devices"
        data-testid="installed_devices_table"
      >
        <thead class="bg-light-0 text-xs uppercase dark:bg-dark-2">
          <tr>
            <th scope="col" class="rounded-tl-xl py-3 pl-6">
              {{ isPlant ? 'Notizen' : 's/N' }}
            </th>
            <th scope="col" class="py-3">
              {{ !isPlant && !isLegacyPlantDevice ? 'Artikelnummer' : '' }}
            </th>
            <th scope="col" class="rounded-tr-xl px-6 py-3 text-right"></th>
          </tr>
        </thead>
        <tbody>
          <DeviceTypeTableElement
            v-for="(device, index) in devices"
            :key="device.id"
            :device="device"
            :is-plant="isPlant"
            :is-last="index === devices.length - 1"
            :on-edit="onEdit"
            :on-delete="onDelete"
            :on-exchange="onExchange"
            :data-testid="
              isPlant ? deviceTypeValue.value : deviceEntryTestId(device)
            "
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import DeviceTypeTableElement from './DeviceTypeTableElement.vue';
import { computed } from 'vue';
import {
  deviceTypeDetails,
  DeviceTypeEnum,
  deviceTypes,
  inspectionDeviceTypeDetails,
} from '@/enums/device';
import InstallationPointTableElement from '@/components/Forms/DeviceListFormComponents/InstallationPointTableElement.vue';
import { PlantType, plantTypeDetails } from '@/models/plant/Plant';
import { Device, DeviceClasses } from '@/models/devices/Device';
import BaseChip from '@/components/UI/Chip/BaseChip.vue';

import { NOT_MOUNTED_ICON } from '@/enums/icons';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import {
  InspectionDevice,
  InspectionDeviceType,
} from '@/models/inspectionPoint/InspectionDevice';
import GatewayStatusBadge from '@/components/LoRaWanRadioCheck/Badges/GatewayStatusBadge.vue';

const props = defineProps<{
  deviceType: string;
  devices: DeviceClasses[] | InstallationPoint[];
  onEdit: (installationPoint: InstallationPoint) => void;
  onDelete: (installationPoint: InstallationPoint) => void;
  onExchange: (installationPoint: InstallationPoint) => void;
}>();

const deviceTypeValue = computed(() => {
  if (props.devices[0] instanceof InspectionDevice) {
    return inspectionDeviceTypeDetails[
      props.deviceType as InspectionDeviceType
    ];
  } else if (isPlant.value) {
    return plantTypeDetails[props.deviceType as PlantType];
  } else {
    return deviceTypeDetails[props.deviceType as DeviceTypeEnum];
  }
});

const installationPoints = computed(() => {
  if (props.deviceType === 'UNMOUNTED') {
    return props.devices;
  }
  return undefined;
});

const devices = computed(() => {
  if (props.deviceType === 'UNMOUNTED') {
    return undefined;
  }
  return props.devices;
});

const isPlant = computed(() => {
  return Object.values(PlantType).includes(props.deviceType);
});

const isLegacyPlantDevice = computed(() => {
  return (
    props.deviceType === deviceTypes.LegacyHeatingPlantMeter ||
    props.deviceType === deviceTypes.LegacyWaterPlantMeter
  );
});

const deviceEntryTestId = (device: Device<any>) => {
  const typeLabel = deviceTypeValue.value?.label || '';
  const identifier = device.serialNumber
    ? `-sn-${device.serialNumber}`
    : `-an-${device.productKey}`;
  return `${typeLabel}${identifier}`;
};
</script>

<style></style>
