<template>
  <div class="relative">
    <DeviceTypeSelection
      v-if="!deviceType && !isCustomForm"
      :device-type="deviceType"
      @update:device-type="onDeviceTypeSelection"
    />

    <NoMountingSelectorInspection
      v-if="inspectionPoint && isAdapterForm(inspectionPoint)"
      ref="noMountingSelectorRef"
      v-model:isMounted="isMounted"
      v-model:failedInstallationAttempt="
        inspectionPoint.failedInstallationAttempt
      "
      v-model:counter-pressure="inspectionPoint.inspectionData.counterPressure"
      :inspection-point="inspectionPoint"
      label="Keine Montage möglich"
      @update:is-mounted="onIsMountedToggle"
    />

    <component
      :is="formComponent"
      v-if="deviceType && inspectionPoint && !isCustomForm && isMounted"
      ref="formComponentRef"
      v-model:device="inspectionDevice"
      v-model:installationPoint="inspectionPoint"
      v-model:inspectionData="inspectionData"
      data-testid="deviceForm"
      @close-and-save-form="handleSubmit"
    />

    <AdapterForm
      v-if="isCustomForm"
      ref="adapterFormRef"
      :inspection-point="inspectionPoint"
    />
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineProps,
  onBeforeMount,
  provide,
  ref,
} from 'vue';
import DeviceTypeSelection from '@/components/Forms/DeviceFormComponents/DeviceTypeSelection.vue';
import { DeviceTypeEnum, InspectionDeviceTypeEnum } from '@/enums/device';
import {
  InspectionPoint,
  InspectionPointType,
} from '@/models/inspectionPoint/InspectionPoint';
import { useInspectionForm } from '@/composables/useInspectionForm';
import { useInspectionDeviceStore } from '@/store/entities/inspectionDeviceStore';
import { InspectionDeviceType } from '@/models/inspectionPoint/InspectionDevice';
import AdapterForm from '@/components/Forms/DeviceFormComponents/DeviceTypeForms/InspectionForms/AdapterForm.vue';
import { useFormStore } from '@/store/form/formStore';
import NoMountingSelectorInspection from '@/components/Forms/DeviceFormComponents/NoMountingSelectorInspection.vue';

const props = defineProps<{
  node?: InspectionPoint;
  parentId: string;
  payload?: any;
}>();
const emit = defineEmits(['update-entity', 'update-entities']);

const {
  inspectionDevice,
  inspectionPoint,
  inspectionData,
  initInspectionDevice,
  initInspectionPoint,
  initInspectionData,
  updateInspectionPoint,
  updateInspectionDevice: updateNewDevice,
} = useInspectionForm(emit);

type FormComponentRef = {
  isFormValid: boolean;
  saveImage: (id: string) => void;
  saveRadiator: () => void;
};

const deviceType = ref<InspectionDeviceType>(props.payload?.deviceType);
const isCustomForm = ref(false);
const formComponentRef = ref<FormComponentRef>();
const adapterFormRef = ref<FormComponentRef>();

const deviceComponents = {
  SMART_RADIATOR_THERMOSTAT: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/InspectionForms/SRTInspectionForm.vue'
  ),
  LO_RA_WAN_GATEWAY: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/LoRaWanGatewayForm.vue'
  ),
};

const isMounted = ref<boolean>(true);
provide('isMounted', isMounted);
const noMountingSelectorRef = ref();
const onIsMountedToggle = () => {
  inspectionPoint.value.accessories = [];
};

const isInspectionDeviceType = (
  value: string
): value is InspectionDeviceTypeEnum => {
  return Object.values(InspectionDeviceTypeEnum).includes(
    value as InspectionDeviceTypeEnum
  );
};

const onDeviceTypeSelection = (newDeviceType: InspectionDeviceType) => {
  deviceType.value = newDeviceType;

  if (!isInspectionDeviceType(newDeviceType)) {
    initCustomForm();
  } else {
    initStandardForm(newDeviceType);
  }

  if (inspectionDevice.value)
    useFormStore().changeCurrentNode(inspectionDevice.value);
};

const initCustomForm = () => {
  if (props.node?.id || deviceType.value) {
    isCustomForm.value = true;
  }
  initInspectionPoint(props.parentId, props.node);
  inspectionPoint.value?.setSupportedDeviceType(
    DeviceTypeEnum.SMART_RADIATOR_THERMOSTAT
  );

  if (inspectionPoint.value) {
    inspectionPoint.value.inspectionData.inspectionType =
      InspectionPointType.SRT_ADAPTER;
    useFormStore().changeCurrentNode(inspectionPoint.value);
  }
};

const isAdapterForm = (inspectionPoint: InspectionPoint) => {
  return (
    inspectionPoint.inspectionData.inspectionType ===
    InspectionPointType.SRT_ADAPTER
  );
};

const initStandardForm = (newDeviceType: InspectionDeviceType) => {
  initInspectionPoint(props.parentId);
  initInspectionDevice(newDeviceType);

  if (deviceType.value === DeviceTypeEnum.LO_RA_WAN_GATEWAY) {
    fillLoRaGatewayForInspection();
  }
};

onBeforeMount(() => {
  if (props.node?.id && !isAdapterForm(props.node)) {
    initInspectionPoint(props.parentId, props.node);
    loadExistingDevice();
  } else if (props.node?.id && isAdapterForm(props.node)) {
    initCustomForm();
  }

  if (inspectionPoint?.value?.failedInstallationAttempt) {
    isMounted.value = false;
  }

  initInspectionData();
});

const loadExistingDevice = () => {
  const activeDeviceId = inspectionPoint.value?.activeTestDeviceId;

  if (!activeDeviceId) {
    throw new Error('Inspection point without device');
  }

  const activeDevice =
    useInspectionDeviceStore().inspectionDevices.get(activeDeviceId);

  if (activeDevice) {
    deviceType.value = activeDevice.deviceType;
    initInspectionDevice(activeDevice.deviceType, activeDevice);
  }
};

const fillLoRaGatewayForInspection = () => {
  const gateways = useInspectionDeviceStore().getAllActiveGateways();

  if (gateways.length > 0 && inspectionDevice.value) {
    inspectionDevice.value.eui = gateways[0].eui;
    inspectionDevice.value.productKey = gateways[0].productKey;
    inspectionDevice.value.serialNumber = gateways[0].serialNumber;
    inspectionDevice.value.note = gateways[0].note;
  }
};

const handleSubmit = () => {
  if (
    inspectionPoint.value?.supportedDeviceType ===
    DeviceTypeEnum.LO_RA_WAN_GATEWAY
  ) {
    inspectionPoint.value?.addInspectionData(inspectionData.value);
  }

  if (
    inspectionDevice.value &&
    !inspectionPoint.value.failedInstallationAttempt
  ) {
    updateNewDevice();
  } else {
    updateInspectionPoint();
  }

  if (!isInspectionDeviceType(deviceType.value)) {
    updateInspectionPoint();
  }

  const formComponent = formComponentRef.value?.saveImage
    ? formComponentRef.value
    : adapterFormRef.value;

  if (formComponent && inspectionPoint.value) {
    formComponent.saveImage(inspectionPoint.value.id);
  }
};

const isFormValid = computed(() => {
  if (inspectionPoint?.value?.failedInstallationAttempt) {
    return noMountingSelectorRef.value?.isFormValid;
  }
  if (isCustomForm.value) {
    return adapterFormRef.value?.isFormValid;
  }
  return formComponentRef.value?.isFormValid;
});

defineExpose({
  isFormValid,
  handleSubmit,
  hasImage: computed(() => formComponentRef.value?.hasImage),
});

const formComponent = computed(() => {
  const deviceTypeIndex = deviceType.value;
  if (deviceTypeIndex) {
    return defineAsyncComponent({
      loader: () => deviceComponents[deviceTypeIndex],
    });
  }
  return null;
});
</script>
