import axios from 'axios';
import { apiEndpoint } from '@/enums/generic';

const businessEntitiesUrl = apiEndpoint.businessEntitiesUrl;

export const getBusinessEntityById = async function (businessEntityId) {
  if (!businessEntityId || typeof businessEntityId !== 'string') {
    throw new Error('No Valid ID was provided ' + businessEntityId);
  }
  return axios({
    method: 'GET',
    url: `${businessEntitiesUrl}/${businessEntityId}`,
  }).catch(function (error) {
    return error.response;
  });
};

export const putBusinessEntity = async function (businessEntity, workTypeCode) {
  let url = `${businessEntitiesUrl}/${businessEntity.id}`;
  if (workTypeCode != null) {
    url += `?workTypeCode=${workTypeCode}`;
  }

  return axios({
    method: 'PUT',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(businessEntity),
  });
};

export const putRawBusinessEntity = async function (businessEntity) {
  const payload = JSON.stringify(businessEntity);

  return axios({
    method: 'POST',
    url: `${businessEntitiesUrl}/${businessEntity.id}/backup`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  });
};

export const getAllBusinessEntities = async function () {
  return axios({
    method: 'GET',
    url: `${businessEntitiesUrl}`,
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch(function (error) {
    return error.response;
  });
};
