<template>
  <div
    class="mb-3 flex flex-col rounded-2xl border border-slate-300 px-5 py-2 dark:border-dark-3 dark:bg-dark-2/50"
  >
    <header
      :class="[
        'mb-3 ml-2 mt-2 flex justify-end',
        { 'justify-between': showHeading },
      ]"
    >
      <div v-if="showHeading" data-testid="cardHeading">{{ heading }}</div>
      <em
        class="mdi mdi-close cursor-pointer text-2xl text-slate-500 dark:text-light-1"
        data-testid="removeCardButton"
        @click="removeCard"
      />
    </header>
    <slot />
  </div>
</template>
<script setup lang="ts">

const props = defineProps<{
  showHeading?: boolean;
  heading?: string;
}>();

const emit = defineEmits(['remove-card']);

const removeCard = () => {
  emit('remove-card');
};
</script>
