<template>
  <BaseInformation
    v-if="userAuth.message"
    :information-text="userAuth.message"
    :status="userAuth?.isSuccessful() ? Status.SUCCESS : Status.ERROR"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { UserAuth } from '@/models/auth/UserAuth';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import { Status } from '@/enums/generic';

export default defineComponent({
  name: 'AuthenticationMessage',
  components: { BaseInformation },
  computed: {
    Status() {
      return Status;
    },
  },
  props: {
    userAuth: {
      type: Object as () => UserAuth,
      required: true,
    },
  },
});
</script>
