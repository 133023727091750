import { AccessoryType } from '@/enums/device';
import { generateUniqueId } from '@/helpers/uuidUtils';

export abstract class Accessory {
  public id: string;
  public type: AccessoryType;
  public unknownProperties: string;
  public mountedAt?: string;
  public unmountedAt?: string;

  protected constructor(params: any) {
    this.id = generateUniqueId();
    this.type = params.type;
    this.unknownProperties = params.unknownProperties ?? undefined;
    this.mountedAt = params.mountedAt;
    this.unmountedAt = params.unmountedAt;
  }
}

export class Adapter extends Accessory {
  public productKey: string;
  public customizedNumber: string;

  constructor(params?: any) {
    super({ type: params?.type });
    this.productKey = '';
    this.customizedNumber = '';
  }
}

export class BatteryPack extends Accessory {
  public customizedNumber: string;
  public serialNumber: string;

  constructor(params?: any) {
    super({
      type: AccessoryType.SRT_BATTERY_PACK,
      mountedAt: params?.mountedAt ?? new Date().toISOString(),
    });
    this.serialNumber = params?.serialNumber ?? '';
    this.customizedNumber = params?.customizedNumber ?? '';
  }
}
