<template>
  <div class="py-2" :data-testid="`section_${testId}`">
    <div class="flex items-center py-1 font-bold">
      <div>
        {{ heading }}
        <span
          v-if="hint"
          class="inline-flex h-5 w-5 items-center justify-center text-xs"
          type="info"
        >
          {{ hint }}
        </span>
        <span
          v-if="label"
          class="ml-1 inline-flex h-5 w-5 items-center justify-center rounded-md bg-dark-5 text-sm text-white shadow"
          type="info"
        >
          {{ label }}
        </span>
        <span
          v-if="!isRequired"
          class="ml-1 text-xs font-light text-light-disabled-text dark:text-dark-disabled-text"
          >(optional)</span
        >
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { Validation } from '@vuelidate/core';

interface Props {
  heading?: string;
  label?: string;
  hint?: string;
  validation?: Validation;
  required?: boolean;
  testId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  heading: undefined,
  label: undefined,
  hint: undefined,
  validation: undefined,
  required: true,
  testId: undefined,
});

const isRequired = computed(() => {
  if (props.validation) {
    return !props.validation.required?.$params?.optional;
  }
  return props.required;
});
</script>
