export const allgemeineNutzung = 'Allgemeine Nutzung';

export const usageTypeList = Object.freeze([
  { name: 'Nutzeinheit', value: 'LIVING', icon: 'sofa' },
  { name: 'Gewerbeeinheit', value: 'BUSINESS', icon: 'briefcase' },
  { name: 'Allgemeine Nutzung', value: 'GENERAL', icon: 'home-roof' },
]);

export enum roomGroupUsageTypes {
  LIVING = 'LIVING',
  BUSINESS = 'BUSINESS',
  GENERAL = 'GENERAL',
}

export const roomgroupNotAccessibleReasonsList = Object.freeze([
  {
    name: 'Nicht angetroffen, Name korrekt',
    value: 'Nicht angetroffen, Name korrekt',
  },
  {
    name: 'trotz Klingeln, nicht geöffnet',
    value: 'trotz Klingeln, nicht geöffnet',
  },
  {
    name: 'Nutzer verweigert Zutritt aktuell',
    value: 'Nutzer verweigert Zutritt aktuell',
  },
  {
    name: 'Kontaktpers. nicht erreichbar',
    value: 'Kontaktpers. nicht erreichbar',
  },
  { name: 'RM-Montage wurde verweigert', value: 'RM-Montage wurde verweigert' },
  { name: 'Besonderheiten beachten', value: 'Besonderheiten beachten' },
  {
    name: 'neuer Termin nötig (Abl./Monteur)',
    value: 'neuer Termin nötig (Abl./Monteur)',
  },
  {
    name: 'Zutritt verweigert durch Monteur',
    value: 'Zutritt verweigert durch Monteur',
  },
  {
    name: 'Nutzer verweigert Zutritt dauerhaft',
    value: 'Nutzer verweigert Zutritt dauerhaft',
  },
  { name: 'Nutzeinheit nicht gefunden', value: 'Nutzeinheit nicht gefunden' },
  { name: 'Nicht zumutbar', value: 'Nicht zumutbar' },
  { name: 'Sonstiges', value: 'Sonstiges' },
]);

export const AVERAGE_SRT_PER_ROOM_GROUP = 4.85;
