import {
  AccessoryType,
  batteryPackTypeList,
  DeviceTypeEnum,
} from '@/enums/device';
import {
  BatteryPackScanResult,
  DeviceScanResult,
  LoRaWanMatrixScanResult,
  SRTAdapterScanResult,
} from '@/models/scanner/ScanResult';
import { Notification } from '@/models';
import { useNotificationStore } from '@/store/notifications/notificationStore';

export interface DataMatrixCodeParseResult {
  productKey?: string;
  customizedNumber?: string;
  manufacturerId?: string;
  yearOfConformity?: number;
  manufacturingDate?: string;
  dueDate?: string;
  serialNumber?: string;
  comprehensiveId?: string;
  unknownProperties?: string;
  version?: number;
  serialNumberBatteryPack?: string;
  eui?: string;
}

const dataMatrixIdentifier = {
  V1: /.*/s,
  V2: /^P.*1.*\+.*P.*\+10V.*\+1K.*\+4K.*\+1B.*\+16Q.*\+22P.*\+Q.*\+12D.*\+S.*$/i,
};

const identifier: Map<keyof DataMatrixCodeParseResult, string> = new Map();
identifier.set('productKey', 'P');
identifier.set('customizedNumber', '1P');
identifier.set('manufacturerId', '10V');
identifier.set('yearOfConformity', '9D');
identifier.set('manufacturingDate', '12D');
identifier.set('dueDate', '5D');
identifier.set('serialNumber', 'S');
identifier.set('comprehensiveId', '1S');
identifier.set('serialNumberBatteryPack', 'MB');

/**
 *
 */
export const fieldHeuristics: {
  [key: string]: (values: string[]) => string | number | undefined;
} = Object.freeze({
  // Should only have 8 digits
  serialNumber: (values) => values.find((entry) => /^\d{8}$/.test(entry)),

  // Should be 8 digits starting with 20 and be a valid date
  manufacturingDate: (values) =>
    values.find((entry) => /^(20)(\d{6})$/.test(entry) && isValidDate(entry)),

  // Should be two letters followed by numbers and a space in between
  productKey: (values) =>
    values.find((entry) => /^([A-Z]{2,})(.*\s+.*)$/.test(entry)),

  // Should be 4 digits starting with 20 or 19
  yearOfConformity: (values) => {
    // Should be 4 digits starting with 20 or 19
    const result = values.find((entry) => /^(20|19)(\d{2})$/.test(entry));
    return result === undefined ? result : parseInt(result);
  },
});

/**
 * Checks if a string of numbers is a valid date or not
 * @param {string} data
 * @return true or false
 */
export function isValidDate(data: string) {
  data = data.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'); // Inserting '-' to make a yyyy-mm-dd format
  return (
    new Date(data).toString() !== 'Invalid Date' &&
    !isNaN(Number(new Date(data)).valueOf())
  );
}

/**
 * Parse a data-matrix-string according to "Qundis Data Matrix Code (DMC) with identifier" specification.
 * @param {string} scanContent data matrix value
 * @param deviceType
 * @return object with properties according to data matrix spec
 */
export const parseDataMatrix = (
  scanContent: string,
  deviceType?: AccessoryType | DeviceTypeEnum
) => {
  if (deviceType === DeviceTypeEnum.LO_RA_WAN_GATEWAY) {
    return getLoRaWanMatrixResult(scanContent);
  }

  if (deviceType === AccessoryType.SRT_ADAPTER) {
    const parseResult = getDefaultMatrixResult(
      scanContent
    ) as SRTAdapterScanResult;
    return new SRTAdapterScanResult()
      .addCustomizedNumber(parseResult.customizedNumber)
      .addProductKey(parseResult.productKey)
      .addUnknownProperties(parseResult.unknownProperties);
  }

  if (deviceType === AccessoryType.SRT_BATTERY_PACK) {
    return getBatteryPackSnMatrixResult(scanContent);
  }

  if (scanContent.startsWith('EIE;')) {
    return getEIEMatrixResult(scanContent);
  }

  return getDefaultMatrixResult(scanContent);
};

const getDefaultMatrixResult = function (
  data: string
): DataMatrixCodeParseResult | undefined {
  if (dataMatrixIdentifier.V2.test(data)) {
    return parseDataMatrixV2(data);
  }
  return parseDataMatrixV1(data);
};

const parseDataMatrixV1 = function (data: string) {
  const result: any = {
    unknownProperties: data,
  };
  return extractDataMatrixProperties(data, result);
};

const parseDataMatrixV2 = function (data: string) {
  const result: any = {
    unknownProperties: data,
  };
  const productKey = data.split(' ')[0];

  const output = extractDataMatrixProperties(data, result);
  output.productKey = productKey.slice(1);

  return output;
};

const extractDataMatrixProperties = function (data: string, result: any) {
  const values = data.split('+');
  const output = values.reduce((currentData, entry) => {
    for (const [name, prefix] of identifier.entries()) {
      if (entry.startsWith(prefix)) {
        currentData[name] = entry.slice(prefix.length);
        return currentData;
      }
    }
    return currentData;
  }, result);

  if (
    'yearOfConformity' in output &&
    output['yearOfConformity'] !== undefined
  ) {
    output['yearOfConformity'] = parseInt(output['yearOfConformity']);
  }

  for (const [key, heuristic] of Object.entries(fieldHeuristics)) {
    if (!(key in output)) {
      const valueByHeuristic = heuristic(values);
      if (valueByHeuristic !== undefined) {
        output[key] = valueByHeuristic;
      }
    }
  }

  return output as DataMatrixCodeParseResult;
};

const getEIEMatrixResult = function (
  data: string
): DeviceScanResult | undefined {
  const values = data.split(';');
  if (values.length !== 6) {
    return undefined;
  }

  return new DeviceScanResult()
    .addProductKey('SDTHZZ7H3000')
    .addVersion(parseInt(values[3], 16))
    .addDueDate(
      '20' + values[5].substring(2) + '-' + values[5].substring(0, 2) + '-01'
    )
    .addSerialNumber(values[1])
    .addManufacturerId(values[0]);
};

const getBatteryPackSnMatrixResult = function (
  data: string
): BatteryPackScanResult | undefined {
  const values = data.split('-');
  if (values.length !== 4) {
    return undefined;
  }

  const customizedNumberBatteryPack = batteryPackTypeList.find(
    (batteryPack) => batteryPack.deviceSKU === values[3]
  )?.productKey as string;
  return new BatteryPackScanResult(
    values[0],
    customizedNumberBatteryPack,
    data
  );
};

const getLoRaWanMatrixResult = function (
  data: string
): LoRaWanMatrixScanResult | undefined {
  const values = data.split('+');
  if (values.length !== 5 && values.length !== 2) {
    console.error('No valid LoRaWan matrix data found');
    useNotificationStore().addNotification(
      new Notification()
        .setTitle('Fehler')
        .setText(
          'Dieser QR-Code wird leider nicht unterstützt. Bitte geben Sie die Informationen manuell ein.'
        )
        .setType('error')
    );
    return;
  }

  const eui = values
    .find((value) => value.startsWith('S'))
    ?.replace('S', '')
    .toLowerCase() as string;

  return new LoRaWanMatrixScanResult()
    .addProductKey(values[0].replace('P', ''))
    .addEUI(eui)
    .addSerialNumber(
      eui.length >= 8 ? `0x${eui.substring(eui.length - 8)}` : eui
    )
    .addUnknownProperties(data);
};
