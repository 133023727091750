<template>
  <div class="flex flex-col gap-4">
    <div
      v-for="(adapter, index) in accessories"
      :key="adapter.id"
      :data-testid="`adapterDetails-${index}`"
    >
      <AdapterDetails
        v-model:adapter="accessories[index]"
        :remove-adapter="removeAdapter"
        :is-last-element="isLastElement(index)"
        :disabled="!isEditable(adapter)"
        @on-adapter-change="adapterChange"
      />
    </div>

    <PlusButton
      test-id="addAdapter"
      title="Adapter hinzufügen"
      @add-element="addAdapter"
    />
  </div>
</template>
<script setup lang="ts">
import { Adapter } from '@/models/installationPoint/Accessory';
import AdapterDetails from '@/components/Forms/DeviceFormComponents/AdapterDetails.vue';
import { computed, ref } from 'vue';
import PlusButton from '@/components/UI/Button/PlusButton.vue';
import { ModelRef } from 'vue';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import { useOrderStore } from '@/store/order/orderStore';
import {
  EntityTransactionType,
  TransactionDiff,
  TransactionDiffType,
} from '@/models/OrderChanges';
import { AccessoryType } from '@/enums/device';

const model: ModelRef<InstallationPoint | InspectionPoint> = defineModel({
  required: true,
});

const emit = defineEmits(['onAdapterChange']);

const createdAdapters = ref<Adapter[]>([]);

const isEditable = (adapter: Adapter) => {
  const isInspectionOrder = useOrderStore().isOnSiteInspection();
  return isInspectionOrder || isNewAccessory(adapter);
};

const accessories = computed(() => {
  return model.value.accessories.filter(
    (accessory) =>
      accessory.type !== AccessoryType.SRT_BATTERY_PACK &&
      !accessory.unmountedAt
  );
});

const addAdapter = () => {
  const adapter = new Adapter({ type: AccessoryType.SRT_ADAPTER });
  adapter.mountedAt = new Date();
  model.value.accessories.push(adapter);
  createdAdapters.value.push(adapter);
};

const removeAdapter = (adapter: Adapter) => {
  if (isNewAccessory(adapter)) {
    model.value.accessories = model.value.accessories.filter(
      (accessory) => accessory.id !== adapter.id
    );
  } else {
    adapter.unmountedAt = new Date();
  }
  /*  const index = model.value.accessories.findIndex(
    (accessory) => accessory.id === adapter.id
  );

  const accessoriesCopy = [...model.value.accessories];
  accessoriesCopy.splice(index, 1);
  adapterChange(adapter);
  model.value.accessories = accessoriesCopy;*/
};

const isNewAccessory = (accessory: Adapter): boolean => {
  if (createdAdapters.value.includes(accessory)) return true;

  const entityTransactions =
    useOrderStore().getOrderChanges()?.transactions[model.value.id];
  if (!entityTransactions) return false;

  if (entityTransactions.type === EntityTransactionType.CREATE_ENTITY) {
    return true;
  }

  if (entityTransactions.type === EntityTransactionType.UPDATE_ENTITY) {
    return entityTransactions.diff.some(
      (diff: TransactionDiff) =>
        diff.type === TransactionDiffType.CREATE &&
        diff.path.includes(accessory.id)
    );
  }
  return false;
};

const isLastElement = (index) => model.value.accessories.length === index + 1;

const adapterChange = (adapter: Adapter) => {
  // re-measuring not needed for this adapter
  if (adapter.customizedNumber != '42050') {
    emit('onAdapterChange', adapter);
  }
};
</script>
