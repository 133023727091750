import {
  and,
  helpers,
  maxLength,
  minLength,
  requiredIf,
  requiredUnless,
} from '@vuelidate/validators';

import { computed, Ref } from 'vue';
import { Building, Room, Roomgroup } from '@/models';
import {
  roomgroupNotAccessibleReasonsList,
  usageTypeList,
} from '@/enums/roomgroup';
import {
  deviceTypes,
  notMountedReasonsListInspection,
  SRTHardwareData,
} from '@/enums/device';

/*
 * if german translation is needed, validators
 * can be overwritten in the file below
 */
import { messages, required } from './messages';
import { User } from '@/models/auth/User';
import {
  DeviceClasses,
  DeviceType,
  isLegacyPlantMeterDevice,
  isMeasuringDevice,
  isMeteringDevice,
  LegacyMeteringDevice,
} from '@/models/devices/Device';
import {
  DeviceEvent,
  DeviceEventParams,
} from '@/models/installationPoint/DeviceEvent';
import {
  GSRadiator,
  GGRadiator,
  GRRadiator,
  PGRadiator,
  HWRadiator,
  PPRadiator,
  UnknownRadiator,
} from '@/models/radiators/Radiator';
import { FailedInstallationAttempt } from '@/models/installationPoint/InstallationPoint';
import { Adapter, BatteryPack } from '@/models/installationPoint/Accessory';
import { PlantDataClasses, TemperatureSetting } from '@/models/plant/Plant';
import { roomNotAccessibleReasonsList } from '@/enums/room';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';

type ValidatorFn<T> = T & any;
type ValidationRule<T> = T extends Record<string, unknown>
  ? VectorValidationRule<T>
  : ScalarValidationRule<T>;
type ScalarValidationRule<T> = { [name: string]: ValidatorFn<T> };
type VectorValidationRule<T extends Record<string, unknown>> = {
  [Key in keyof T]+?: ValidationRule<T[Key]>;
};
type ValidationGroup = { [name: string]: Array<string> };

export const validationRules: Record<
  string,
  (state: any, options?: any) => Record<string, unknown>
> = {};

const isConstructionYearValid =
  (isOnSiteInspection?: boolean) =>
  (value: any): boolean => {
    return isOnSiteInspection
      ? new RegExp(/^[12]\d{3}$/, 'i').test(value)
      : true;
  };

validationRules['building'] = (
  state: {
    building: Ref<Building>;
  },
  options?: {
    isOnSiteInspection?: boolean;
  }
): { building: ValidationRule<Building> } => {
  return {
    building: {
      address: {
        street: { required },
        houseNumber: { required },
        zipCode: { required },
        city: { required },
      },
      entrance: { required },
      usageType: { required },
      buildingSection: { required },
      constructionYear: {
        required: helpers.withMessage(
          messages.invalidConstructionYear,
          isConstructionYearValid(options?.isOnSiteInspection)
        ),
      },
      floorType: {
        required: requiredIf(() => {
          return (
            !!options?.isOnSiteInspection && state.building.value.isAccessible
          );
        }),
      },
      interiorWallType: {
        required: requiredIf(() => {
          return (
            !!options?.isOnSiteInspection && state.building.value.isAccessible
          );
        }),
      },
      exteriorWallType: {
        required: requiredIf(!!options?.isOnSiteInspection),
      },
      reasonNotAccessible: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return state.building.value.isAccessible;
          })
        ),
      },
      dateNotAccessible: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return state.building.value.isAccessible;
          })
        ),
      },
    },
  };
};

const isDeviceReferenceValid =
  () =>
  (value: any): boolean => {
    if (!value) {
      return true;
    }
    return new RegExp(/^\d{8}$/).test(value);
  };

validationRules['roomgroup'] = (state: {
  roomgroup: Ref<Roomgroup>;
}): { roomgroup: ValidationRule<Roomgroup> } => {
  return {
    roomgroup: {
      number: {
        required: helpers.withParams(
          {
            type: 'required',
            optional: computed(
              () => state.roomgroup.value.usageType === usageTypeList[2].value
            ),
          },
          helpers.withMessage(
            messages.required,
            requiredUnless(() => {
              return state.roomgroup.value.usageType === usageTypeList[2].value;
            })
          )
        ),
      },
      doorbellName: {
        required: helpers.withParams(
          {
            type: 'required',
            optional: computed(
              () =>
                state.roomgroup.value.usageType === usageTypeList[2].value ||
                state.roomgroup.value.isVacant
            ),
          },
          helpers.withMessage(
            messages.required,
            requiredUnless(() => {
              return (
                state.roomgroup.value.usageType === usageTypeList[2].value ||
                state.roomgroup.value.isVacant
              );
            })
          )
        ),
      },
      reasonNotAccessible: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return state.roomgroup.value.isAccessible;
          })
        ),
      },
      note: {
        required: helpers.withParams(
          {
            type: 'required',
            optional: computed(
              () =>
                state.roomgroup.value.reasonNotAccessible !==
                roomgroupNotAccessibleReasonsList[11].value
            ),
          },
          helpers.withMessage(
            messages.required,
            requiredUnless(() => {
              return (
                state.roomgroup.value.reasonNotAccessible !==
                roomgroupNotAccessibleReasonsList[11].value
              );
            })
          )
        ),
      },
      dateNotAccessible: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return state.roomgroup.value.isAccessible;
          })
        ),
      },
      deviceReference: {
        required: helpers.withMessage(
          messages.invalidDeviceReference,
          and(isDeviceReferenceValid())
        ),
      },
    },
  };
};

validationRules['inspectionPoint'] = (state: {
  inspectionPoint: Ref<InspectionPoint>;
}): { inspectionPoint: ValidationRule<InspectionPoint> } => {
  return {
    inspectionPoint: {
      accessories: {
        required: requiredIf(
          () =>
            !state.inspectionPoint.value.failedInstallationAttempt &&
            !state.inspectionPoint.value.inspectionData.noAdapterNeeded
        ),
      },
      inspectionData: {
        counterPressure: {
          required: helpers.withMessage(
            () =>
              messages.invalidInspectionPointCounterPressure(
                state.inspectionPoint
              ),
            counterPressureInspectionPointIsValid(state)
          ),
        },
      },
    },
  };
};

validationRules['room'] = (state: {
  room: Ref<Room>;
}): { room: ValidationRule<Room> } => {
  return {
    room: {
      floorLevel: {
        type: { required },
      },
      usageType: { required },
      reasonNotAccessible: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return state.room.value.isAccessible;
          })
        ),
      },
      dateNotAccessible: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return state.room.value.isAccessible;
          })
        ),
      },
      note: {
        required: helpers.withParams(
          {
            type: 'required',
            optional: computed(
              () =>
                state.room.value.reasonNotAccessible !==
                roomNotAccessibleReasonsList[5].value
            ),
          },
          helpers.withMessage(
            messages.required,
            requiredUnless(() => {
              return (
                state.room.value.reasonNotAccessible !==
                roomNotAccessibleReasonsList[5].value
              );
            })
          )
        ),
      },
    },
  };
};

const manufacturerIsValid =
  (device: Ref<DeviceClasses>) =>
  (value: any): boolean => {
    if (device.value.deviceType === deviceTypes.SmartRadiatorThermostat) {
      return value.toLowerCase() === SRTHardwareData.MANUFACTURER;
    }
    return true;
  };

const serialNumberIsValid =
  (device: Ref<DeviceClasses>) =>
  (value: any): boolean => {
    if (device.value.deviceType === deviceTypes.LoRaWanGateway) {
      return true;
    }

    if (device.value.deviceType === deviceTypes.SmartRadiatorThermostat) {
      return new RegExp(/^[A-Z0-9]{12}$/i).test(value);
    }
    return new RegExp(/^\d{8}$/).test(value);
  };

const isYearOfConformityValid =
  (state: { device: Ref<DeviceClasses> }) =>
  (value: any): boolean => {
    if (
      isMeteringDevice(state.device.value.deviceType) &&
      state.device.value.deviceType !== deviceTypes.HeatCostAllocator
    ) {
      return new RegExp(/^20\d{2}$/, 'i').test(value);
    }
    return true;
  };

const counterPressureInspectionPointIsValid =
  (state: { inspectionPoint: Ref<InspectionPoint> }) =>
  (value: any): boolean => {
    if (
      state.inspectionPoint.value.failedInstallationAttempt &&
      state.inspectionPoint.value.failedInstallationAttempt.reason ===
        notMountedReasonsListInspection[2].value
    ) {
      return new RegExp(/^\d+\.?\d*$/, 'i').test(value);
    } else if (state.inspectionPoint.value.failedInstallationAttempt) {
      return true;
    } else {
      let maxValue = /^(0*(?:[1-8]?\d(?:\.\d)?|90(?:\.0)?))$/;
      if (
        state.inspectionPoint.value.accessories.find(
          (accessory) => accessory.customizedNumber === '42051'
        )
      ) {
        maxValue = /^(0*(?:[1-7]?\d(?:\.\d)?|80(?:\.0)?))$/;
      }
      return new RegExp(maxValue, 'i').test(value);
    }
  };

const counterPressureIsValid =
  (state: { device: Ref<DeviceClasses> }, isOnSiteInspection?: boolean) =>
  (value: any): boolean => {
    if (
      state.device.value.deviceType === deviceTypes.SmartRadiatorThermostat &&
      !isOnSiteInspection
    ) {
      return new RegExp(/^(0*(?:[1-8]?\d(?:\.\d)?|90(?:\.0)?))$/, 'i').test(
        value
      );
    }
    return true;
  };

const isLegacyDeviceYearOfConformityValid =
  (deviceType?: DeviceType | undefined) =>
  (value: any): boolean => {
    if (
      (isLegacyPlantMeterDevice(deviceType) && value === undefined) ||
      value === null
    ) {
      return true;
    }
    return new RegExp(/^20\d{2}$/, 'i').test(value);
  };

const isDateValid = (tempSettings: Ref<TemperatureSetting>) => (): boolean => {
  if (!tempSettings.value.startDate || !tempSettings.value.endDate) {
    return true;
  }
  const start = Date.parse(tempSettings.value.startDate);
  const end = Date.parse(tempSettings.value.endDate);

  return start < end;
};

const euiIsValid =
  (device: Ref<DeviceClasses>) =>
  (value: any): boolean => {
    if (device.value.deviceType === deviceTypes.LoRaWanGateway) {
      return new RegExp(/^[A-F0-9]{16}$/i).test(value);
    }
    return true;
  };

const optional = (value: any) => {
  return !helpers.req(value) || helpers.req(value);
};

validationRules['device'] = (
  state: {
    device: Ref<DeviceClasses>;
  },
  options?: {
    isOnSiteInspection?: boolean;
  }
): {
  device: ValidationRule<DeviceClasses>;
} => {
  return {
    device: {
      id: { required },
      type: { required },
      deviceType: { required },
      installationPointId: {
        required: requiredIf(() => {
          return !options?.isOnSiteInspection;
        }),
      },
      detectionType: {
        required: requiredIf(() => {
          return !options?.isOnSiteInspection;
        }),
      },
      isAutomatedMeterReading: {
        required: requiredIf(() => {
          return !options?.isOnSiteInspection;
        }),
      },
      serialNumber: {
        required: helpers.withMessage(
          messages.invalidSerialNumber(state.device),
          serialNumberIsValid(state.device)
        ),
      },
      productKey: { required },
      manufacturer: {
        required: helpers.withMessage(
          messages.invalidManufacturer(state.device),
          manufacturerIsValid(state.device)
        ),
      },
      eui: {
        required: helpers.withMessage(
          messages.invalidEui,
          euiIsValid(state.device)
        ),
      },
      yearOfConformity: {
        required: helpers.withMessage(
          messages.invalidYearOfConformity,
          isYearOfConformityValid(state)
        ),
      },
      measurementUnit: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return !isMeasuringDevice(state.device.value.deviceType);
          })
        ),
      },
      counterPressure: {
        required: helpers.withMessage(
          messages.invalidCounterPressure,
          and(
            requiredIf(() => {
              return (
                state.device.value.deviceType ===
                  deviceTypes.SmartRadiatorThermostat &&
                !options?.isOnSiteInspection
              );
            }),
            counterPressureIsValid(state, options?.isOnSiteInspection)
          )
        ),
      },
      note: { optional },
      unknownProperties: { optional },
      funkcheckStatus: { optional },
    },
  };
};

validationRules['batteryPack'] = (options?: {
  isOnSiteInspection?: boolean;
}): {
  batteryPack: ValidationRule<BatteryPack>;
} => {
  return {
    batteryPack: {
      serialNumber: {
        required: helpers.withMessage(
          messages.invalidSerialNumberBatteryPack,
          and(
            requiredIf(() => !options?.isOnSiteInspection),
            (value) => new RegExp(/^[A-Za-z0-9-]{12}$/, 'i').test(value)
          )
        ),
      },
      customizedNumber: {
        required: requiredIf(!options?.isOnSiteInspection),
      },
    },
  };
};

validationRules['adapter'] = (state: {
  adapter: Ref<Adapter>;
}): {
  adapter: ValidationRule<Adapter>;
} => {
  if (!state?.adapter?.value?.type) {
    throw new Error('adapterType is not defined');
  }
  return {
    adapter: {
      productKey: { required },
      customizedNumber: { required },
    },
  };
};

const isTimestampValid = (deviceEvent: Ref<DeviceEvent>) => (): boolean => {
  const date = Date.parse(deviceEvent.value.timestamp);
  return date > 0;
};

validationRules['deviceEvent'] = (state: {
  deviceEvent: Ref<DeviceEvent>;
  legacyDevice?: Ref<DeviceClasses>;
  device?: Ref<DeviceClasses>;
}): {
  deviceEvent: ValidationRule<DeviceClasses>;
} => {
  const deviceType: DeviceType | undefined = state.device?.value.deviceType
    ? state.device.value.deviceType
    : state.legacyDevice?.value.deviceType;
  if (!deviceType) {
    throw new Error('deviceType is not defined');
  }
  return {
    deviceEvent: {
      timestamp: {
        required: helpers.withMessage(
          messages.invalidDate,
          isTimestampValid(state.deviceEvent)
        ),
      },
      counter: {
        required: helpers.withMessage(
          messages.required,
          requiredIf(() => {
            return (
              isMeteringDevice(deviceType) &&
              !isLegacyPlantMeterDevice(deviceType)
            );
          })
        ),
      },
    },
  };
};

validationRules['oldDeviceEvent'] = (
  state: {
    oldDeviceEvent?: Ref<DeviceEventParams>;
  },
  options: {
    isReplacement: boolean;
    hasReplacement: boolean;
  }
): {
  oldDeviceEvent: ValidationRule<DeviceEventParams>;
} => {
  return {
    oldDeviceEvent: {
      timestamp: {
        required: requiredIfReplacement(options),
      },
      reason: {
        required: helpers.withMessage(
          messages.required,
          requiredIf(() => options.isReplacement || options.isReplacement)
        ),
      },
    },
  };
};

validationRules['oldDevice'] = (
  state: {
    oldDevice?: Ref<DeviceClasses>;
  },
  options: {
    isReplacement: boolean;
    hasReplacement: boolean;
  }
): {
  oldDevice?: ValidationRule<DeviceClasses>;
} => {
  return {
    oldDevice: {
      serialNumber: {
        required: requiredIfReplacement(options),
      },
      productKey: {
        required: requiredIfReplacement(options),
      },
    },
  };
};

const requiredIfReplacement = (options: Record<any, any>) =>
  helpers.withParams(
    {
      type: 'required',
      optional: computed(() => !options.isReplacement),
    },
    helpers.withMessage(
      messages.required,
      requiredUnless(() => {
        return !options.isReplacement;
      })
    )
  );

validationRules['legacyDevice'] = (state: {
  legacyDevice?: Ref<DeviceClasses>;
}): {
  legacyDevice: ValidationRule<LegacyMeteringDevice<DeviceClasses>>;
} => {
  const deviceType: DeviceType | undefined =
    state.legacyDevice?.value.deviceType;
  return {
    legacyDevice: {
      id: { required },
      type: { required },
      deviceType: { required },
      installationPointId: { required },
      detectionType: { required },
      productKey: { optional },
      isAutomatedMeterReading: { required },
      unknownProperties: { optional },
      funkcheckStatus: { optional },
      energySource: { optional },
      isPrimary: { optional },
      note: { optional },
      serialNumber: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return isLegacyPlantMeterDevice(deviceType);
          })
        ),
      },
      yearOfConformity: {
        required: helpers.withMessage(
          messages.invalidYearOfConformity,
          and(isLegacyDeviceYearOfConformityValid(deviceType))
        ),
      },
      measurementUnit: {
        required: helpers.withMessage(
          messages.required,
          requiredUnless(() => {
            return isLegacyPlantMeterDevice(deviceType);
          })
        ),
      },
    },
  };
};

validationRules['plant'] = (): {
  plant: ValidationRule<PlantDataClasses>;
} => {
  return {
    plant: {
      plantType: {
        required,
      },
    },
  };
};

validationRules['temperatureSetting'] = (state: {
  temperatureSetting?: Ref<TemperatureSetting>;
}): {
  temperatureSetting: ValidationRule<TemperatureSetting>;
} => {
  return {
    temperatureSetting: {
      startDate: {
        required: helpers.withMessage(
          messages.invalidStartDate,
          and(isDateValid(state.temperatureSetting))
        ),
      },
      endDate: {
        required: helpers.withMessage(
          messages.invalidEndDate,
          and(isDateValid(state.temperatureSetting))
        ),
      },

      temperature: {
        optional,
      },
    },
  };
};

validationRules['pp'] = (state: {
  pp: Ref<PPRadiator>;
}): {
  pp: ValidationRule<PPRadiator>;
  $validationGroups: ValidationGroup;
} => {
  return {
    pp: {
      hasCover: { required },
      height: { required },
      length: { required },
      depth: {
        withCoverPanel: {
          required: requiredIf(() => {
            return state.pp.value.hasCoverPanel;
          }),
        },
        withoutCoverPanel: { required },
      },
      hasCoverPanel: { optional },
      spacing: { required },
      panelThickness: { required },
      lamellaDepth: { required },
      layers: { required },
      lamellaShape: { required },
      lamellaConnection: { required },
      connectionType: { required },
      profileShape: {
        distributionChannel: { required },
        transition: { required },
      },
      manufacturer: { required },
      note: { required },
    },
    $validationGroups: {
      dimensions: [
        'pp.height',
        'pp.length',
        'pp.depth.withoutCoverPanel',
        'pp.depth.withCoverPanel',
      ],
      spacingAndPanelThickness: ['pp.spacing', 'pp.panelThickness'],
      profileShape: [
        'pp.profileShape.distributionChannel',
        'pp.profileShape.transition',
      ],
    },
  };
};

validationRules['hw'] = (): {
  hw: ValidationRule<HWRadiator>;
  $validationGroups: ValidationGroup;
} => {
  return {
    hw: {
      pipeCount: { required },
      height: { required },
      length: { required },
      depth: { required },
      spacing: { required },
      pipeStrength: { min: { required }, max: { required } },
      distributionChannelStrength: { min: { required }, max: { required } },
      distributionChannelShape: { required },
      distributionChannelStructure: { required },
      pipeShape: { required },
      pipeTopView: { required },
      pipeConnection: { required },
      pipeRouting: { required },
      connectionType: { required },
      manufacturer: { required },
      note: { required },
    },
    $validationGroups: {
      dimensions: ['hw.height', 'hw.length', 'hw.depth'],
      distributionChannelStrength: [
        'hw.distributionChannelStrength.min',
        'hw.distributionChannelStrength.max',
      ],
      pipeStrength: ['hw.pipeStrength.min', 'hw.pipeStrength.max'],
    },
  };
};

validationRules['gs'] = (): {
  gs: ValidationRule<GSRadiator>;
  $validationGroups: ValidationGroup;
} => {
  return {
    gs: {
      elementCount: { required },
      height: { required },
      length: { required },
      depth: { required },
      spacing: { required },
      waterChamberWidth: { required },
      columnShape: { required },
      connectionType: { required },
      backPlate: { required },
      manufacturer: { required },
      note: { required },
    },
    $validationGroups: {
      dimensions: ['gs.height', 'gs.length', 'gs.depth'],
    },
  };
};

validationRules['gr'] = (): {
  gr: ValidationRule<GRRadiator>;
  $validationGroups: ValidationGroup;
} => {
  return {
    gr: {
      height: { required },
      length: { required },
      depth: { required },
      spacing: { required },
      pipeStrength: { min: { required }, max: { required } },
      typeKey: { required },
      distributionChannelShape: { required },
      pipeShape: { required },
      pipeInnerShape: { required },
      elementCount: { required },
      mountingVariant: { required },
      connectionType: { required },
      manufacturer: { required },
      note: { required },
    },
    $validationGroups: {
      dimensions: ['gr.height', 'gr.length', 'gr.depth'],
      pipeStrength: ['gr.pipeStrength.min', 'gr.pipeStrength.max'],
    },
  };
};

validationRules['gg'] = (): {
  gg: ValidationRule<GGRadiator>;
  $validationGroups: ValidationGroup;
} => {
  return {
    gg: {
      height: { required },
      length: { required },
      depth: { required },
      connectionType: { required },
      spacing: { required },
      elementCount: { required },
      waterChamberWidth: { required },
      columnCount: { required },
      columnShape: { required },
      columnType: { required },
      decoration: { required },
      manufacturer: { required },
      note: { required },
    },
    $validationGroups: {
      dimensions: ['gg.height', 'gg.length', 'gg.depth'],
    },
  };
};

validationRules['pg'] = (): {
  pg: ValidationRule<PGRadiator>;
  $validationGroups: ValidationGroup;
} => {
  return {
    pg: {
      height: { required },
      length: { required },
      depth: { required },
      connectionType: { required },
      spacing: { required },
      panelThickness: { required },
      lamellaDepth: { required },
      lamellaShape: { required },
      lamellaConnection: { required },
      layers: { required },
      profileShape: {
        distributionChannel: { required },
        transition: { required },
      },
      hasCover: { required },
      rotated90degrees: { required },
      gapParameter: { required },
      manufacturer: { required },
      note: { required },
    },
    $validationGroups: {
      dimensions: ['pg.height', 'pg.length', 'pg.depth'],
      spacingAndPanelThickness: ['pg.spacing', 'pg.panelThickness'],
      profileShape: [
        'pp.profileShape.distributionChannel',
        'pp.profileShape.transition',
      ],
    },
  };
};

validationRules['unknown'] = (): {
  unknown: ValidationRule<UnknownRadiator>;
  $validationGroups: ValidationGroup;
} => {
  return {
    unknown: {
      height: { required },
      length: { required },
      depth: { required },
      note: { required },
    },
    $validationGroups: {
      dimensions: ['unknown.height', 'unknown.length', 'unknown.depth'],
    },
  };
};

const passwordIsSame = (user: User) => () => {
  return user.newPassword === user.confirmPassword;
};

validationRules['user'] = (state: {
  user: Ref<User>;
}): {
  user: ValidationRule<User>;
  $validationGroups: ValidationGroup;
} => {
  return {
    user: {
      username: { required },
      password: { required },
      resetCode: {
        required: helpers.withMessage(
          messages.resetCode,
          and(minLength(6), maxLength(6), required)
        ),
      },
      newPassword: {
        required: helpers.withMessage(messages.password, minLength(16)),
      },
      confirmPassword: {
        required: helpers.withMessage(
          messages.sameAsPassword,
          passwordIsSame(state.user.value)
        ),
      },
    },
    $validationGroups: {
      login: ['user.username', 'user.password'],
      resetPassword: [
        'user.resetCode',
        'user.confirmPassword',
        'user.newPassword',
      ],
      forgetPassword: ['user.username'],
      changePassword: ['user.newPassword', 'user.confirmPassword'],
    },
  };
};

validationRules['failedInstallationAttempt'] = (_state: {
  failedInstallationAttempt: Ref<FailedInstallationAttempt>;
}): {
  failedInstallationAttempt: ValidationRule<any>;
} => {
  return {
    failedInstallationAttempt: {
      reason: { required },
      note: { required },
    },
  };
};
