<template>
  <div>
    <BaseRadioButton
      v-if="!plantData"
      v-model:selection="selectedPlant"
      label="PlantType"
      :options-list="i18n.plantOptions"
    />
    <div v-if="plantData">
      <h4>{{ plantTypeDE }} Daten</h4>
      <BaseTextarea
        v-model:input-text="plantData.note"
        label="Notizen"
        test-id="plantNote"
      />

      <BaseSection heading="Anlagenzähler">
        <div
          v-for="(relatedEntities, index) in Object.values(
            relatedDevicesAndInstallationPoints
          )"
          :key="relatedEntities.device.id"
        >
          <RemovableCard
            :heading="isHeatingPlant ? 'Primäre Wärmequelle' : ''"
            :show-heading="relatedEntities.device.isPrimary"
            :data-testid="`deviceCard_${index}`"
            @remove-card="
              deleteLegacyPlantMeter(relatedEntities.installationPoint)
            "
          >
            <LegacyPlantMeterForm
              :device="relatedEntities.device"
              :device-event="relatedEntities.deviceEvent"
              :installation-point="relatedEntities.installationPoint"
            />
          </RemovableCard>
        </div>
        <PlusButton
          test-id="addDevice"
          title="Gerät hinzufügen"
          @add-element="addLegacyPlantMeter(parentId)"
        />
      </BaseSection>

      <BaseSection v-if="isHeatingPlant" heading="Temperatureinstellungen">
        <div
          v-for="(temperatureSettingElement, index) in temperatureSettings"
          :key="temperatureSettingElement"
        >
          <TemperatureSettingForm
            :temperature-setting="temperatureSettingElement"
            :index="index"
            @remove-temperature-setting="removeTemperatureSetting(index)"
          />
        </div>
        <PlusButton
          test-id="addTemperatureSetting"
          title="Temperatureinstellung hinzufügen"
          @add-element="addTemperatureSetting"
        />
      </BaseSection>
    </div>
  </div>
</template>
<script setup lang="ts">
import BaseRadioButton from '@/components/UI/Radio/BaseRadioButton.vue';
import { computed, inject, onBeforeMount, watch } from 'vue';
import { usePlantDataForm } from '@/composables/usePlantDataForm';
import {
  PlantDataClasses,
  PlantType,
  PlantClasses,
} from '@/models/plant/Plant';
import i18n from '@/utils/i18n/plantData.json';
import LegacyPlantMeterForm from './DeviceFormComponents/DeviceTypeForms/LegacyPlantMeterForm.vue';
import TemperatureSettingForm from './PlantDataFormComponents/TemperatureSettingForm.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import PlusButton from '@/components/UI/Button/PlusButton.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import RemovableCard from '@/components/UI/Card/RemovableCard.vue';
import { useValidation } from '@/composables/useValidation';
import { LegacyPlantMeterDevice } from '@/models/devices/Device';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { instanceToInstance } from 'class-transformer';
import {
  FORM_CHANGE_TRACKER,
  useFormChangeTrackerPlugin,
} from '@/composables/useFormChangeTracker';

const props = defineProps<{
  node?: PlantDataClasses;
  parentId: string;
  payload?: any;
}>();

const emit = defineEmits(['update-entity', 'update-entities']);

const {
  plantData,
  relatedDevicesAndInstallationPoints,
  selectedPlant,
  temperatureSettings,
  isHeatingPlant,
  toBeDeletedInstallationPoints,
  addLegacyPlantMeter,
  initRelatedDevicesAndInstallationPoints,
  initPlantData,
  deleteLegacyPlantMeter,
  addTemperatureSetting,
  removeTemperatureSetting,
} = usePlantDataForm(emit);

onBeforeMount(() => {
  if (props.node) {
    const plantDataClone = instanceToInstance(props.node);
    initPlantData(props.node?.plantType, plantDataClone as PlantClasses);
    initRelatedDevicesAndInstallationPoints();
  }
  useFormChangeTrackerPlugin(plantData);
});

watch(selectedPlant, (newValue) => {
  if (newValue) {
    initPlantData(newValue);
    plantData.value?.setParentRoomId(props.parentId);
    initRelatedDevicesAndInstallationPoints();
    trackDeviceFormChanges();
  } else {
    throw new Error('plantType is not defined');
  }
});

const trackFormChanges = inject(FORM_CHANGE_TRACKER);
const trackDeviceFormChanges = () => {
  if (trackFormChanges) {
    trackFormChanges(plantData);
  }
};

const plantTypeDE = computed(() => {
  switch (plantData.value?.plantType) {
    case PlantType.HEATING_PLANT:
      return i18n.plantOptions[0].name;
    case PlantType.WATER_PLANT:
      return i18n.plantOptions[1].name;
    default:
      return '';
  }
});

const handleSubmit = () => {
  toBeDeletedInstallationPoints.value.forEach((installationPoint) => {
    useInstallationPointStore().deleteEntity(installationPoint);
  });

  const devices: LegacyPlantMeterDevice[] = [];
  const installationPoints: InstallationPoint[] = [];

  Object.values(relatedDevicesAndInstallationPoints.value).forEach(
    ({ device, deviceEvent, installationPoint }) => {
      devices.push(device);
      const currentDeviceEvent =
        installationPoint.deviceEvents[
          installationPoint.deviceEvents.length - 1
        ];
      if (currentDeviceEvent && deviceEvent) {
        currentDeviceEvent.timestamp = deviceEvent.timestamp;
        currentDeviceEvent.counter = deviceEvent.counter;
      }
      installationPoints.push(installationPoint);
    }
  );

  emit('update-entities', [plantData.value, ...devices, ...installationPoints]);
};

const { v$, isFormValid } = useValidation({ plant: plantData });

defineExpose({
  handleSubmit,
  isFormValid,
});
</script>
