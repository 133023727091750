<template>
  <div>
    <BaseDropdown
      v-if="isHeatingPlantDevice"
      v-model:selection="legacyDevice.energySource"
      :label="i18n.label.energySource"
      :options-list="i18n.energySourceOptions"
    />

    <div class="md:flex md:gap-4">
      <div class="md:basis-1/2">
        <BaseFormInput
          v-model="legacyDevice.serialNumber"
          label="Seriennummer"
          placeholder="Seriennummer"
          type="text"
          test-id="serialNumber"
        />
      </div>
      <div class="md:basis-1/2">
        <BaseFormInput
          v-model="legacyDevice.yearOfConformity"
          :validation="v$.legacyDevice.yearOfConformity"
          :optional="true"
          label="Eichjahr"
          type="number"
          inputmode="nume"
          pattern="[0-9]{4}"
          placeholder="Eichjahr"
          test-id="yearOfConformity"
        />
      </div>
    </div>
    <MeterReadingLegacyDevice
      v-model:device="legacyDevice"
      v-model:deviceEvent="legacyDeviceEvent"
      :validation="v$"
      :optional="true"
    />
    <BaseToggle
      v-if="isHeatingPlantDevice"
      v-model:is-checked="legacyDevice.isPrimary"
      :is-inverted="true"
      :label="i18n.label.isSupplementary"
      :options-list="i18n.systemTypeOptions"
      test-id="toggle_isPrimary"
    />
    <BaseSection heading="Weitere Angaben">
      <AdditionalInfo v-model:device="legacyDevice" />
      <DateMount
        v-model:deviceEvent="legacyDeviceEvent"
        :validation="v$.deviceEvent"
        label="Aufnahmedatum"
      />
    </BaseSection>
  </div>
</template>

<script setup lang="ts">
import i18n from '@/utils/i18n/plantData.json';
import { computed, WritableComputedRef } from 'vue';
import BaseToggle from '@/components/UI/Switch/BaseToggle.vue';
import {
  LegacyHeatingPlantMeter,
  LegacyPlantMeterDevice,
} from '@/models/devices/Device';
import AdditionalInfo from '@/components/Forms/DeviceFormComponents/AdditionalInfo.vue';
import DateMount from '@/components/Forms/DeviceFormComponents/DateMount.vue';
import MeterReadingLegacyDevice from '@/components/Forms/DeviceFormComponents/MeterReadingLegacyDevice.vue';
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import { useValidation } from '@/composables/useValidation';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { DeviceEvent } from '@/models/installationPoint/DeviceEvent';
import BaseSection from '@/components/UI/Card/BaseSection.vue';

const props = defineProps<{
  device: LegacyPlantMeterDevice;
  deviceEvent: DeviceEvent;
  installationPoint: InstallationPoint;
}>();

const emit = defineEmits(['update:device']);

const legacyDevice: WritableComputedRef<LegacyPlantMeterDevice> = computed({
  get() {
    return props.device;
  },
  set(value) {
    emit('update:device', value);
  },
});

const legacyDeviceEvent = computed(() => {
  return props.deviceEvent;
});

const isHeatingPlantDevice = computed(() => {
  return legacyDevice.value instanceof LegacyHeatingPlantMeter;
});

const { v$, isFormValid } = useValidation({
  legacyDevice,
  deviceEvent: legacyDeviceEvent,
});

defineExpose({
  isFormValid,
});
</script>
