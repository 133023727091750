<template>
  <RemovableCard
    :heading="`${localTemperature} °C (${startDate} ${endDate})`"
    :show-heading="!!(localTemperature && localStartDate)"
    :data-testid="`temperatureSettingCard_${index}`"
    @remove-card="removeTemperatureSetting"
  >
    <div class="flex justify-between gap-4">
      <div class="w-full">
        <BaseFormInput
          v-model="temperatureSettingLocal.startDate"
          :label="i18n.label.startDate"
          type="date"
          test-id="startDateInput"
          :validation="v$.temperatureSetting.startDate"
        />
      </div>
      <div class="w-full">
        <BaseFormInput
          v-model="temperatureSettingLocal.endDate"
          :label="i18n.label.endDate"
          type="date"
          test-id="endDateInput"
          :validation="v$.temperatureSetting.endDate"
        />
      </div>
    </div>
    <div class="flex justify-between gap-4">
      <div class="w-full">
        <BaseFormInput
          v-model.number="temperatureSettingLocal.ingressTemperature"
          label="Temperatur"
          test-id="ingressTemperature"
          inputmode="numeric"
          pattern="[0-9]*"
          type="number"
        />
      </div>
      <div class="w-full">
        <div class="py-3">
          <div class="relative">
            <label
              class="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 text-sm text-gray-500 dark:text-gray-400"
            >
              Maßeinheit
            </label>
            <div
              class="px-2.5 pb-2.5 pt-5 text-sm text-gray-900 dark:text-white"
            >
              °C
            </div>
          </div>
        </div>
      </div>
    </div>
  </RemovableCard>
</template>

<script setup lang="ts">
import i18n from '@/utils/i18n/plantData.json';
import { computed, WritableComputedRef } from 'vue';
import { TemperatureSetting } from '@/models/plant/Plant';
import RemovableCard from '@/components/UI/Card/RemovableCard.vue';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import { useValidation } from '@/composables/useValidation';

const props = defineProps<{
  temperatureSetting: TemperatureSetting;
  index: number;
}>();

const emit = defineEmits([
  'remove-temperature-setting',
  'update:temperatureSetting',
]);

const temperatureSettingLocal: WritableComputedRef<TemperatureSetting> =
  computed({
    get() {
      return props.temperatureSetting;
    },
    set(value) {
      emit('update:temperatureSetting', value);
    },
  });

const localTemperature = computed(() => {
  return temperatureSettingLocal.value.ingressTemperature;
});

const localStartDate = computed(() => {
  return temperatureSettingLocal.value.startDate;
});

const localEndDate = computed(() => {
  return temperatureSettingLocal.value.endDate;
});

const startDate = computed(() => {
  const startDate = new Date(temperatureSettingLocal.value.startDate);
  const startDay = startDate.getDate().toString().padStart(2, '0');
  const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0');
  const startYear = startDate.getFullYear().toString();

  return localStartDate.value ? `${startDay}.${startMonth}.${startYear}` : '';
});

const endDate = computed(() => {
  const endDate = new Date(temperatureSettingLocal.value.endDate);
  const endDay = endDate.getDate().toString().padStart(2, '0');
  const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0');
  const endYear = endDate.getFullYear().toString();

  return localStartDate.value && localEndDate.value
    ? ` - ${endDay}.${endMonth}.${endYear}`
    : '';
});

const removeTemperatureSetting = () => {
  emit('remove-temperature-setting');
};

const { v$ } = useValidation({
  temperatureSetting: temperatureSettingLocal,
});
</script>
