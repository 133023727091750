import { ref } from 'vue';
import {
  FailedInstallationAttempt,
  InstallationPoint,
  InstallationPointParams,
} from '@/models/installationPoint/InstallationPoint';
import { InspectionData } from '@/models/inspectionPoint/InspectionPoint';
import {
  DeviceClasses,
  DeviceFactory,
  DeviceType,
  DeviceTypeParams,
  LegacyMeteringDevice,
} from '@/models/devices/Device';
import { DeviceEventParams } from '@/models/installationPoint/DeviceEvent';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { AccessoryType, DeviceTypeEnum, deviceTypes } from '@/enums/device';
import { FormAction, useFormStore } from '@/store/form/formStore';
import { instanceToInstance } from 'class-transformer';
import { Adapter, BatteryPack } from '@/models/installationPoint/Accessory';

export function useDeviceForm(emit: any) {
  const device = ref<DeviceClasses>();
  const installationPoint = ref<InstallationPoint>();
  const deviceEvent = ref<DeviceEventParams>();
  const failedInstallationAttempt = ref<FailedInstallationAttempt>(
    new FailedInstallationAttempt()
  );
  const inspectionData = ref<InspectionData>(new InspectionData());
  const armInstallationPointIds = ref<string[]>([]);

  const initInstallationPoint = (
    roomId: string,
    params?: InstallationPoint | InstallationPointParams
  ) => {
    installationPoint.value = new InstallationPoint(roomId);
    const room = useEntitiesStore().getEntityById(roomId);
    if (!room?.roomGroupId) {
      throw new Error('RoomGroupId is not defined');
    }
    installationPoint.value.billedInRoomGroupId = room.roomGroupId;

    if (params) {
      installationPoint.value?.updateProperties(params);
    }
    initFailedInstallationAttempt();
  };

  const initDeviceEvent = () => {
    if (!installationPoint.value) {
      throw new Error('Installation point is not initialized');
    }

    deviceEvent.value = {
      timestamp: new Date().toISOString(),
      counter:
        installationPoint.value.supportedDeviceType ===
        deviceTypes.HeatCostAllocator
          ? 0
          : undefined,
    };

    if (useFormStore().formAction === FormAction.EDIT) {
      const mountEvent = installationPoint.value?.getActiveDeviceEvent();
      if (installationPoint.value?.deviceEvents.length > 0 && mountEvent) {
        deviceEvent.value = instanceToInstance(mountEvent);
      }
    }
  };

  const initFailedInstallationAttempt = () => {
    if (installationPoint.value?.failedInstallationAttempt) {
      failedInstallationAttempt.value =
        installationPoint.value?.failedInstallationAttempt;
    }
  };

  const initDevice = <T extends DeviceType>(
    deviceType: T,
    params?: Extract<DeviceTypeParams, { deviceType: T }>
  ) => {
    if (!installationPoint.value) {
      throw new Error('Installation point is not initialized');
    }

    device.value = DeviceFactory.createDevice(deviceType);
    if (params) {
      if (installationPoint.value?.supportedDeviceType !== deviceType) {
        throw new Error('Device types are not matching');
      }

      if (installationPoint.value?.id !== params?.installationPointId) {
        throw new Error('Installation point ids are not matching');
      }

      device.value.updateProperties(params);
    } else {
      device.value.setInstallationPointId(installationPoint.value.id);
      installationPoint.value?.setSupportedDeviceType(deviceType);
    }
    initDeviceEvent();
  };

  const initAccessories = (isMounted = true) => {
    if (!device.value) {
      throw new Error('Device is not initialized');
    }

    if (!installationPoint.value) {
      throw new Error('Installation point is not initialized');
    }

    const { supportedDeviceType, accessories } = installationPoint.value;
    if (supportedDeviceType === DeviceTypeEnum.SMART_RADIATOR_THERMOSTAT) {
      if (isMounted) {
        if (
          !accessories.some((a) => a.type === AccessoryType.SRT_BATTERY_PACK)
        ) {
          accessories.push(new BatteryPack());
        }
      } else {
        installationPoint.value.accessories = accessories.filter(
          (a) => a.type !== AccessoryType.SRT_BATTERY_PACK
        );
      }
    }
  };

  const updateAccessories = (isMounted: boolean) => initAccessories(isMounted);

  const createDevice = () => {
    if (!installationPoint.value) {
      throw new Error('Installation point is not initialized');
    }

    if (!device.value) {
      throw new Error('Device is not initialized');
    }

    if (!deviceEvent.value) {
      throw new Error('DeviceEvent is not initialized');
    }

    if (device.value instanceof LegacyMeteringDevice) {
      installationPoint.value.captureDevice(
        device.value.id,
        deviceEvent.value.timestamp,
        deviceEvent.value.counter
      );
    } else {
      installationPoint.value.mountDevice(
        device.value.id,
        deviceEvent.value.timestamp,
        deviceEvent.value.counter
      );
    }
    updateDevice();
  };

  const updateDevice = () => {
    if (!installationPoint.value) {
      throw new Error('Installation point is not initialized');
    }

    if (!device.value) {
      throw new Error('DeviceClasses is not initialized');
    }

    if (useFormStore().formAction === FormAction.EDIT) {
      const currentDeviceEvent =
        installationPoint.value?.getActiveDeviceEvent();
      if (currentDeviceEvent && deviceEvent.value) {
        currentDeviceEvent.timestamp = deviceEvent.value.timestamp;
        currentDeviceEvent.counter = deviceEvent.value.counter;
      }
    }

    emit('update-entities', [device.value, installationPoint.value]);
  };

  return {
    installationPoint,
    device,
    deviceEvent,
    inspectionData,
    failedInstallationAttempt,
    armInstallationPointIds,
    initDevice,
    initAccessories,
    initInstallationPoint,
    initDeviceEvent,
    createDevice,
    updateDevice,
    updateAccessories,
  };
}
